import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import Header from './Header';
import ProtectedRoute from '../routes/ProtectedRoute';
import Login from '../routes/Login';
import Dashboard from '../routes/Dashboard';
import AddProject from '../routes/AddProject';
import ProjectsTable from '../routes/ProjectsTable';
import UpdateCredentials from '../routes/UpdateCredentials';
import EditProject from '../routes/EditProject';
import Workforce from '../routes/Workforce';
import ProjectTree from '../routes/ProjectTree';
import Reports from '../routes/Reports';
import NotAuthorized from '../routes/NotAuthorized';
import NotFound from '../routes/NotFound';
import Footer from './Footer';
import '../styles/main.scss';

/**
 * Web app components root
 *
 * @component
 * @version 1.0
 */
const App = () => {
  return (
    <Router>
      <Header />
      <Switch>
        <Route exact path="/" component={Login} />
        <ProtectedRoute exact path="/dashboard" component={Dashboard} />
        <ProtectedRoute exact path="/add-project" component={AddProject} />
        <ProtectedRoute exact path="/all-projects" component={ProjectsTable} />
        <ProtectedRoute
          exact
          path="/update-credentials"
          component={UpdateCredentials}
        />
        <ProtectedRoute
          exact
          path="/all-projects/edit-project/:id"
          component={EditProject}
        />
        <ProtectedRoute
          exact
          path="/all-projects/work-force/:id"
          component={Workforce}
        />
        <ProtectedRoute
          exact
          path="/all-projects/project-tree/:id"
          component={ProjectTree}
        />
        <ProtectedRoute
          exact
          path="/all-projects/reports/:id"
          component={Reports}
        />
        <Route path="/not-authorized" component={NotAuthorized} />
        <Route path="*" component={NotFound} />
      </Switch>
      <Footer />
    </Router>
  );
};

export default App;
