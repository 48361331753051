/**
 * @category APIs
 * @module projects
 * @version 1.0
 */
import axios from 'axios';

/**
 * Creates a new project
 *
 * @param {object} data - User inputs
 * @param {string} data.name - Project name
 * @param {string} data.orderSheet - Order Id
 * @param {string} data.customerOrderSheet - Client order Id
 * @param {string} data.budget - Project budget
 * @param {string} data.externalBudget - Project external budget
 * @param {number} data.status - Project status code
 * @param {number} data.type - Project type code
 * @param {Array.<{category: string, number: string, hours: string}>} data.categories - Project work force categories
 * @returns {{status: number,
 * data: {{name: string, orderSheet: string, customerOrderSheet: string,
 * budget: number, externalBudget: string, status: number, type: number}}}} Query result
 */
const createProject = async (data) => {
  try {
    return await axios.post('/projects', data, {
      withCredentials: true
    });
  } catch ({ response }) {
    return response;
  }
};

/**
 * Retrieves all available projects
 *
 * @param {boolean} isArchived - Active [true] and Archived [false] projects
 * @returns {{status: number, data: Array.<{id: number, key: string,
 * name: string, orderSheet: string, customerOrderSheet: string,
 * budget: number, externalBudget: number, status: string, statusKey: number, type: number,
 * categories: Array.<{categoryId: number, category: string, hours: number, number: number}>,
 * aliases: Array.<{projectId: number, id: number, layerAlias: string, layerDepth: number}>}>}} Query result
 */
const getAllProjects = async (isArchived) => {
  try {
    return await axios.get(
      '/projects',
      {
        params: { archived: isArchived }
      },
      {
        withCredentials: true
      }
    );
  } catch ({ response }) {
    return response;
  }
};

/**
 *  Updates project record data
 *
 * @param {number} projectID - Project Id
 * @param {string} data.name - Project name
 * @param {string} data.orderSheet - Order Id
 * @param {string} data.customerOrderSheet - Client order Id
 * @param {number} data.budget - Project budget
 * @param {number} data.status - Project status code
 * @returns {{status: number,
 * data: {id: number, number: string, name: string, orderSheet: string,
 * customerOrderSheet: string, budget: number, externalBudget: string,
 * status: number, type: number}}} Query result
 */
const updateProject = async (projectID, data) => {
  try {
    return await axios.patch(`/projects/${projectID}`, data, {
      withCredentials: true
    });
  } catch ({ response }) {
    return response;
  }
};

/**
 * Delete a project record data
 *
 * @param {number} projectID - Project Id
 * @returns {{status: number, data: {message: string}}} Query result
 */
const deleteProject = async (projectID) => {
  try {
    return await axios.delete(`/projects/${projectID}`, {
      withCredentials: true
    });
  } catch ({ response }) {
    return response;
  }
};

/**
 *
 * @param {object} params - Query parameters
 * @param {number} params.id - Project id in the database
 * @param {number} params.uploadType - Upload type (1 = Wako, 2 = External)
 * @returns {{status: number, data: {message: string}}} Query result
 */
const uploadProject = async (params) => {
  try {
    return await axios.post('/projects/upload', params, {
      withCredentials: true
    });
  } catch ({ response }) {
    return response;
  }
};

export {
  createProject,
  getAllProjects,
  updateProject,
  deleteProject,
  uploadProject
};
