import React from 'react';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Form, Input, Button } from 'antd';
import { UserOutlined, LockOutlined } from '@ant-design/icons';

/**
 * User credentials form, Used in both logging in and updating credentials
 *
 * @component
 * @version 1.0
 */
const CredentialsForm = ({ formItemLayout, submitButtonLayout, onSubmit }) => {
  const { pathname } = useLocation();
  const { t } = useTranslation();

  return (
    <Form
      className={pathname === '/' && 'login__user-credentials'}
      {...formItemLayout}
      name="Login Form"
      onFinish={onSubmit}
    >
      <Form.Item
        label={t('username')}
        labelAlign="left"
        name="username"
        rules={[{ required: true, message: t('enterUsername') }]}
      >
        <Input prefix={<UserOutlined className="site-form-item-icon" />} />
      </Form.Item>

      {pathname === '/update-credentials' && (
        <Form.Item
          label={t('oldPassword')}
          labelAlign="left"
          name="oldPassword"
          rules={[{ required: true, message: t('enterOldPasswordMessage') }]}
        >
          <Input.Password
            prefix={<LockOutlined className="site-form-item-icon" />}
            type="password"
          />
        </Form.Item>
      )}

      <Form.Item
        label={
          pathname === '/update-credentials' ? t('newPassword') : t('password')
        }
        labelAlign="left"
        name="password"
        rules={[
          {
            required: true,
            message:
              pathname === '/update-credentials'
                ? t('enterNewPasswordMessage')
                : t('enterPasswordMessage')
          }
        ]}
      >
        <Input.Password
          prefix={<LockOutlined className="site-form-item-icon" />}
          type="password"
        />
      </Form.Item>

      {pathname === '/update-credentials' && (
        <Form.Item
          label={t('confirmPassword')}
          labelAlign="left"
          name="confirmedPassword"
          dependencies={['password']}
          hasFeedback
          rules={[
            {
              required: true,
              message: t('confirmPasswordMessage')
            },
            ({ getFieldValue }) => ({
              validator(rule, value) {
                if (!value || getFieldValue('password') === value) {
                  return Promise.resolve();
                }

                return Promise.reject(t('rejectPasswordMessage'));
              }
            })
          ]}
        >
          <Input.Password
            prefix={<LockOutlined className="site-form-item-icon" />}
          />
        </Form.Item>
      )}

      <Form.Item {...submitButtonLayout}>
        <Button style={{ width: 100 }} type="primary" htmlType="submit">
          {pathname === '/update-credentials' ? t('update') : t('login')}
        </Button>
      </Form.Item>
    </Form>
  );
};

CredentialsForm.propTypes = {
  /** Form items styles */
  formItemLayout: PropTypes.shape({
    /** Label layout */
    labelCol: PropTypes.shape({
      span: PropTypes.number
    }),
    /** Input controls layout */
    wrapperCol: PropTypes.shape({
      span: PropTypes.number
    })
  }),
  /** Submit button styles */
  submitButtonLayout: PropTypes.shape({
    /** Input controls layout */
    wrapperCol: PropTypes.shape({
      /** X-Small breakpoint width */
      xs: PropTypes.shape({
        span: PropTypes.number,
        offset: PropTypes.number
      }),
      /** Small breakpoint width */
      sm: PropTypes.shape({
        span: PropTypes.number,
        offset: PropTypes.number
      })
    })
  }),
  /** Form submit handler */
  onSubmit: PropTypes.func.isRequired
};

CredentialsForm.defaultProps = {
  formItemLayout: null,
  submitButtonLayout: null
};

export default CredentialsForm;
