import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Upload, Radio } from 'antd';
import { InboxOutlined } from '@ant-design/icons';

/**
 * File uploader component, Supports drag and drop.
 *
 * @component
 * @version 1.0
 */
const Uploader = ({ projectID, projectType }) => {
  const { t } = useTranslation();
  const [uploadType, setUploadType] = useState(projectType);

  return (
    <>
      <Upload.Dragger
        name="excel"
        accept=".xls, .xlsx"
        action={`/projects/upload?id=${projectID}&uploadType=${uploadType}`}
        method="put"
        multiple={false}
      >
        <p className="ant-upload-drag-icon">
          <InboxOutlined />
        </p>
        <p>{t('uploadHint')}</p>
      </Upload.Dragger>

      {projectType === 2 && (
        <Radio.Group
          className="uploader"
          onChange={(event) => setUploadType(event.target.value)}
          value={uploadType}
        >
          <Radio value={1}>{t('wakoProject')}</Radio>
          <Radio value={2}>{t('externalProject')}</Radio>
        </Radio.Group>
      )}
    </>
  );
};

Uploader.propTypes = {
  /** Project id in the database */
  projectID: PropTypes.number.isRequired,
  /** Project type (Wako = 1 or External = 2) */
  projectType: PropTypes.number.isRequired
};

export default Uploader;
