import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import produce from 'immer';
import moment from 'moment';
import { v4 as uuidv4 } from 'uuid';
import { Button, Breadcrumb, Tabs, DatePicker, Form, Input } from 'antd';
import {
  FolderOpenTwoTone,
  FileExcelTwoTone,
  ArrowLeftOutlined,
  SettingOutlined
} from '@ant-design/icons';
import Notification from '../components/Notification';
import BackButton from '../components/BackButton';
import Table from '../components/Table';
import useSafeState from '../hooks/useSafeState';
import useTable from '../hooks/useTable';
import {
  getProjectTree,
  updateWorkData,
  updateCategoriesNames
} from '../api/project-tree';

const { TabPane } = Tabs;

/**
 * Shows the selected project deeply nested data in a table
 * and The user can go back and forth through these levels in a file explorer fashion
 *
 * @category Routes
 * @component
 * @version 1.0
 */
const ProjectTree = () => {
  const userRole = sessionStorage.getItem('role');
  const [breadcrumbItems, setBreadcrumbItems] = useSafeState([]);
  const [date, setDate] = useSafeState(undefined);
  const [tabActiveKey, setTabActiveKey] = useSafeState('1');
  const [categoriesForm] = Form.useForm();
  const [tableType, setTableType] = useSafeState('normal');
  const [tableColumns, setTableColumns] = useSafeState([]);
  const [categories, setCategories] = useSafeState([]);
  const [isFetching, setIsFetching] = useSafeState(true);
  const {
    state: { number: projectID, id }
  } = useLocation();
  const { t } = useTranslation();
  const { form, data, setData, isEditing, edit, cancel } = useTable([]);
  const isNormalTable = tableType === 'normal' || tableType === 'category';
  const isUser = userRole !== 'admin' && !isNormalTable;

  /**
   * Modifies the categorized table rows data and its children rows data
   *
   * @param {object} tableData - Table rows
   * @param {number} tableData.id - Row Id
   * @param {string} tableData.categoryKey - Category Id
   * @param {Array.<{actualTotal: number, actualUnitCount: number,
   * categoryID: number, categoryKey: string, costDifferenceJPY: number,
   * costDifferencePercentage: number, id: number, isTitle: boolean, key: string,
   * listedTotal: number, listedUnitCount: number, listedUnitPrice: number,
   * number: string, unit: string, workName: string}>} tableData.children - Category data
   */
  const modifyCategorizedTableData = (tableData) => {
    const categorizedTableData = [...tableData];
    categorizedTableData.forEach((categoryData, dataIndex) => {
      const { id: categoryID, categoryKey, children: rows } = categoryData;

      rows.forEach((row, rowIndex) => {
        rows[rowIndex] = {
          ...row,
          categoryKey,
          categoryID,
          key: uuidv4()
        };
      });

      categorizedTableData[dataIndex] = {
        categoryID,
        categoryKey,
        children: rows
      };
    });

    return categorizedTableData;
  };

  /**
   * Fetches and Updates the table data based on the selected date
   *
   * @param {object} value - Moment
   */
  const onDateChange = async (value) => {
    const lastItemIndex = breadcrumbItems.length - 1;
    const { ID: currentParentID } = breadcrumbItems[lastItemIndex];
    const queryParameters = {
      projectid: id,
      parent: currentParentID,
      ...(userRole !== 'admin' && {
        date: value.format('YYYY-MM-DD')
      })
    };

    setIsFetching(true);

    const response = await getProjectTree(queryParameters);
    const { status } = response;

    setIsFetching(false);

    if (status === 200) {
      const {
        data: { data: tableData }
      } = response;
      const isExcelData = tableType === 'data' || tableType === 'dataExternal';
      const isExcelCategorizedData =
        tableType === 'categoryData' || tableType === 'categoryDataExternal';

      if (isExcelData) {
        setData(tableData);
      } else if (isExcelCategorizedData) {
        const categorizedTableData = modifyCategorizedTableData(tableData);
        setCategories(categorizedTableData);
      }
    } else {
      Notification('error', t('error'), t('serverDownMessage'));
    }

    setDate(value.format('YYYY-MM-DD'));
  };

  /**
   * Handles the navigation logic
   * - Sends an API request to the backend
   * - Fetches the appropriate data by passing project id, record id and date if any.
   * - Updates the table rows data
   *
   * @param {string} direction - Navigation direction
   * @param {object} record - Table row
   * @param {number} record.id - Row database id
   * @param {boolean} record.endpoint - Whether the project is the last item in the tree or not
   * @param {string} record.key - Row key
   * @param {string} record.ln - Layer number
   * @param {string} record.name - Alias
   * @param {string} record.nextLayerName - Next layer name
   * @param {string} record.specification - Row short description
   */
  const explore = async (direction, record) => {
    const queryParameters = {
      projectid: id,
      parent: record.id,
      ...(userRole !== 'admin' && { date: moment().format('YYYY-MM-DD') })
    };

    setIsFetching(true);

    const response = await getProjectTree(queryParameters);
    const { status } = response;

    if (status === 200) {
      const {
        data: { data: tableData, type }
      } = response;
      const isCategory =
        type === 'category' ||
        type === 'categoryData' ||
        type === 'categoryDataExternal';

      if (type === 'normal' || type === 'data' || type === 'dataExternal') {
        setData(tableData);
        setCategories([]);
      } else if (isCategory) {
        const categorizedTableData = modifyCategorizedTableData(tableData);
        setCategories(categorizedTableData);
      }

      setTableType(type);

      if (direction === 'backward') {
        const breadcrumbStack = [...breadcrumbItems];
        breadcrumbStack.pop();

        const lastItemIndex = breadcrumbStack.length - 1;
        const { savedTabActiveKey } = breadcrumbStack[lastItemIndex];

        setTabActiveKey(savedTabActiveKey);
        setBreadcrumbItems(breadcrumbStack);
      } else {
        if (isCategory) {
          setTabActiveKey('1');
        }

        setBreadcrumbItems([
          ...breadcrumbItems,
          {
            ID: record.id,
            text: `${record.nextLayerName} ${t('list')}` || record.id,
            type,
            record
          }
        ]);
      }
      setIsFetching(false);
    } else {
      Notification('error', t('error'), t('serverDownMessage'));
    }
  };

  /**
   * Handles the backward navigation logic
   */
  const onGoBack = () => {
    const index = breadcrumbItems.length - 2;
    const { record } = breadcrumbItems[index];
    explore('backward', record);
  };

  /**
   * Updates the table data of type data or dataExternal
   *
   * @param {object} record - Table row [Defined @save function]
   * @param {object} editedRow - Edited values
   * @param {number} editedRow.listedUnitPrice - Listed unit price
   * @param {number} editedRow.listedUnitCount - Listed unit count
   * @param {number} editedRow.otherUnitPrice - Other unit price
   * @param {number} editedRow.otherUnitCount - Other unit count
   * @param {number} editedRow.actualUnitCount - Actual unit count
   * @returns {number} API request status code
   */
  const updateExcelData = async (record, editedRow) => {
    const newData = [...data];
    const index = newData.findIndex((item) => record.key === item.key);
    const item = newData[index];
    const {
      listedUnitPrice,
      listedUnitCount,
      otherUnitPrice,
      otherUnitCount,
      actualUnitCount
    } = editedRow;
    let rowData = {
      listedUnitPrice,
      listedUnitCount,
      actualUnitCount
    };

    if (date) {
      const { inputAmount } = editedRow;
      rowData = { inputAmount, date };
    } else if (tableType === 'dataExternal') {
      rowData = { ...rowData, otherUnitPrice, otherUnitCount };
    }

    const response = await updateWorkData(record.id, rowData);
    const { status } = response;

    if (status === 200) {
      const { data: updatedData } = response;

      newData.splice(index, 1, { ...item, ...editedRow, ...updatedData });

      setData(newData);
    }

    return status;
  };

  /**
   * Updates the table rows data of type categoryData or categoryDataExternal
   *
   * @param {object} record - Table row [Defined @save function]
   * @param {object} editedRow - Edited values
   * @param {number} editedRow.listedUnitPrice - Listed unit price
   * @param {number} editedRow.listedUnitCount - Listed unit count
   * @param {number} editedRow.otherUnitPrice - Other unit price
   * @param {number} editedRow.otherUnitCount - Other unit count
   * @param {number} editedRow.actualUnitCount - Actual unit count
   * @returns {number} API request status code
   */
  const updateExcelCategorizedData = async (record, editedRow) => {
    const {
      listedUnitPrice,
      listedUnitCount,
      otherUnitPrice,
      otherUnitCount,
      actualUnitCount
    } = editedRow;
    let rowData = {
      listedUnitPrice,
      listedUnitCount,
      actualUnitCount
    };

    if (tableType === 'categoryDataExternal' && !date) {
      rowData = { ...rowData, otherUnitPrice, otherUnitCount };
    } else if (date) {
      const { inputAmount } = editedRow;
      rowData = { inputAmount, date };
    }

    const response = await updateWorkData(record.id, rowData);
    const { status } = response;

    if (status === 200) {
      const { data: updatedRowData } = response;
      categories.forEach((categoryData, categoryIndex) => {
        const { categoryID, children: rows } = categoryData;

        if (categoryID === record.categoryID) {
          const rowIndex = rows.findIndex((item) => record.key === item.key);
          const categoriesData = produce(categories, (draftState) => {
            draftState[categoryIndex].children[rowIndex] = {
              ...record,
              ...updatedRowData
            };
          });

          setCategories(categoriesData);
        }
      });
    }

    return status;
  };

  /**
   * Saves the user changes to the table depends on the table.
   * - Sends an API request to the backend with the required data.
   * - If the operation succeed the UI gets updated and the user is notified.
   * - If the operation failed the UI is not updated and the user is notified.
   * - Cancels any opened edit operation.
   *
   * @param {object} record - Table row
   * @param {string} record.key - Row key for table
   * @param {number} record.id - Row id in the database
   * @param {string} record.number - Project code number
   * @param {string} record.categoryID - Category id in the database
   * @param {number} record.categoryKey - Category name
   * @param {string} record.workName - Work name
   * @param {boolean} record.isTitle - Whether the work name is a row title or not
   * @param {string} record.unit - Unit
   * @param {number} record.listedUnitPrice - Listed unit price
   * @param {number} record.listedUnitCount - Listed unit count
   * @param {number} record.listedTotal - Listed total = Listed unit price * Listed unit count
   * @param {number} record.otherUnitPrice - Other unit price
   * @param {number} record.otherUnitCount - Other unit count
   * @param {number} record.otherTotal - Other total = Other unit price * Other unit count
   * @param {number} record.actualUnitCount - Actual unit count
   * @param {number} record.actualTotal - If the project type is wako:
   * Actual total = Actual unit count * Listed unit price
   * If the project type is external:
   * Actual total = Actual unit count * Other unit price
   * @param {number} record.costDifferenceJPY - Cost difference (JPY) = Listed total - Actual total
   * @param {number} record.costDifferencePercentage - If the project type is wako:
   * Cost difference (%) = (Cost difference (JPY) / Listed total) * 100
   * If the project type is external:
   * Cost difference (%) = (Cost difference (JPY) / Other total) * 100
   */
  const save = async (record) => {
    const isExcelData = tableType === 'data' || tableType === 'dataExternal';
    const isExcelCategorizedData =
      tableType === 'categoryData' || tableType === 'categoryDataExternal';
    const { operations, ...editedRow } = await form.validateFields();
    let status;

    if (isExcelData) {
      status = await updateExcelData(record, editedRow);
    } else if (isExcelCategorizedData) {
      status = await updateExcelCategorizedData(record, editedRow);
    }
    console.info(editedRow);
    console.info(record.actualUnitCount);

    if (status === 200) {
      Notification('success', t('great'), t('updatesSaved'));
    } else {
      Notification('error', t('error'), t('tryAgain'));
    }

    cancel();
  };

  /**
   * Creates the appropriate component code to be shown inside the corresponding cell
   * based on the table column index
   *
   * @param {string} dataIndex - Table column index
   * @returns {string} Component code
   */
  const getInputType = (dataIndex) => {
    if (dataIndex === 'workName') {
      return 'text';
    }
    if (dataIndex === 'operations') {
      return 'button-group';
    }
    return 'number';
  };

  /**
   * Creates table columns based on the table type
   *
   * @returns {Array.<{title: string, dataIndex: string,
   * editable: boolean, render: Function, onCell: Function}>} Table columns
   */
  const getTableColumns = () => {
    const operations = {
      title: t('operations'),
      dataIndex: 'operations',
      editable: true,
      render: (_, record) => {
        const obj = {
          children: undefined,
          props: {}
        };

        if (!record.isTitle) {
          obj.children = (
            <Button onClick={() => edit(record)}>{t('edit')}</Button>
          );
        } else {
          obj.props.colSpan = 0;
        }

        return obj;
      }
    };
    let columns;

    if (tableType === 'normal' || tableType === 'category') {
      columns = tableColumns;
    } else {
      columns = [...tableColumns, operations];
    }

    return columns.map((column) => {
      if (column.dataIndex === 'category') {
        return {
          ...column,
          render: (_, record) => (
            <Button
              key={record.id}
              type="primary"
              onClick={() => explore('forward', record)}
            >
              {`${record.nextLayerName} ${t('list')}` || record.id}
            </Button>
          )
        };
      }
      if (!column.editable) {
        return column;
      }

      return {
        ...column,
        onCell: (record) => ({
          record,
          inputType: getInputType(column.dataIndex),
          dataIndex: column.dataIndex,
          title: column.title,
          editing: isEditing(record),
          onSave: () => save(record),
          cancel
        })
      };
    });
  };

  /**
   * Handles the table tab change.
   * - Shuts down any active row in edit mode.
   * - Saves the latest active tab key.
   * - Updates the breadcrumb items and the active tab key.
   *
   * @param {string} activeKey - Active tab key
   */
  const onTabsChange = (activeKey) => {
    cancel();
    const breadcrumbStack = [...breadcrumbItems];
    const lastItemIndex = breadcrumbStack.length - 1;

    breadcrumbStack[lastItemIndex] = {
      ...breadcrumbStack[lastItemIndex],
      savedTabActiveKey: activeKey
    };

    setBreadcrumbItems(breadcrumbStack);
    setTabActiveKey(activeKey);
  };

  /**
   * Handles the submission logic of updating the table categories' names
   * - Converts the formData values into the required shape by the backend
   * - Sends an API request to the backend with the required data.
   * - Updates the table categories' names.
   * - Notifies the user whether the update request was a successful or a failure.
   *
   * @param {object} formData - User inputs [The object keys' names are unknown beforehand]
   */
  const onSubmit = async (formData) => {
    const categoriesFormData = Object.keys(formData).map((key) => ({
      id: Number(key),
      specification: formData[key]
    }));
    const response = await updateCategoriesNames(categoriesFormData);
    const { status } = response;
    console.info(categoriesFormData);

    if (status === 200) {
      const {
        data: { layers: updatedData }
      } = response;

      const categoriesData = produce(categories, (draftState) => {
        updatedData.forEach((updatedDataRow) => {
          const index = draftState.findIndex(
            (item) => item.categoryID === updatedDataRow.id
          );

          draftState[index].categoryKey = updatedDataRow.categoryKey;
        });
      });

      setCategories(categoriesData);
      Notification('success', t('great'), t('updatesSaved'));
    } else {
      Notification('error', t('error'), t('serverDownMessage'));
    }
  };

  /**
   * Updates the table rows data on component mounts
   */
  useEffect(() => {
    /**
     * Updates the table rows data based on the project id on component mounts
     * Updates the table type and breadcrumb items
     *
     * @memberof ProjectTree
     */
    const updateTableData = async () => {
      setIsFetching(true);

      const queryParameters = { projectid: id, parent: 0 };
      const response = await getProjectTree(queryParameters);
      const { status } = response;

      if (status === 200) {
        const {
          data: { data: tableData, type }
        } = response;

        setData(tableData);
        setBreadcrumbItems([
          {
            ID: 0,
            text: projectID,
            type: 'normal',
            record: { id: 0 }
          }
        ]);
        setTableType(type);
        setIsFetching(false);
      } else {
        Notification('error', t('error'), t('serverDownMessage'));
      }
    };

    updateTableData();
  }, [
    id,
    projectID,
    setData,
    setBreadcrumbItems,
    setIsFetching,
    setTableType,
    t
  ]);

  /**
   *  Updates the table columns data
   */
  useEffect(() => {
    /**
     * Renders the table's row content
     * Merges/Hides the entire row cells except for the work name column if there's a title to display
     *
     * @memberof ProjectTree
     */
    const renderContent = (value, record, dataIndex, columnsLength) => {
      const { isTitle } = record;

      const obj = {
        children: value,
        props: {}
      };

      if (isTitle) {
        if (dataIndex === 'workName') {
          obj.children = (
            <div
              style={{
                fontSize: 20,
                marginTop: 10,
                marginBottom: -20,
                textDecoration: 'underline'
              }}
            >
              <b>{record.workName}</b>
            </div>
          );
          obj.props.colSpan = columnsLength + 1;
        } else {
          obj.props.colSpan = 0;
        }
      }
      return obj;
    };

    /**
     * Updates the table columns data based on the table type
     *
     * @memberof ProjectTree
     */
    const updateTableColumns = () => {
      let columns;

      if (tableType === 'normal' || tableType === 'category') {
        columns = [
          {
            title: t('alias'),
            dataIndex: 'name'
          },
          {
            title: t('specification'),
            dataIndex: 'specification'
          },
          {
            title: t('breakdown'),
            dataIndex: 'category'
          }
        ];
      } else {
        if (userRole === 'admin') {
          columns = [
            {
              title: t('workName'),
              dataIndex: 'workName'
            },
            {
              title: t('unit'),
              dataIndex: 'unit'
            },
            {
              title: t('listedUnitPrice'),
              dataIndex: 'listedUnitPrice',
              editable: true
            },
            {
              title: t('listedUnitCount'),
              dataIndex: 'listedUnitCount',
              editable: true
            },
            {
              title: t('listedTotal'),
              dataIndex: 'listedTotal'
            },
            {
              title: t('actualUnitCount'),
              dataIndex: 'actualUnitCount',
              editable: true
            },
            {
              title: t('actualTotal'),
              dataIndex: 'actualTotal'
            },
            {
              title: t('costDifferenceJPY'),
              dataIndex: 'costDifferenceJPY'
            },
            {
              title: t('costDifferencePercentage'),
              dataIndex: 'costDifferencePercentage'
            }
          ];

          if (
            tableType === 'dataExternal' ||
            tableType === 'categoryDataExternal'
          ) {
            const externalDataColumns = [
              {
                title: t('otherUnitPrice'),
                dataIndex: 'otherUnitPrice',
                editable: true
              },
              {
                title: t('otherUnitCount'),
                dataIndex: 'otherUnitCount',
                editable: true
              },
              {
                title: t('otherTotal'),
                dataIndex: 'otherTotal'
              }
            ];
            columns = [
              ...columns.slice(0, 5),
              ...externalDataColumns,
              ...columns.slice(4)
            ];
          }
        } else {
          setDate(moment().format('YYYY-MM-DD'));

          columns = [
            {
              title: t('workName'),
              dataIndex: 'workName'
            },
            {
              title: t('actualUnitCount'),
              dataIndex: 'actualUnitCount'
            },
            {
              title: t('inputAmount'),
              dataIndex: 'inputAmount',
              editable: true,
              render: (_, record) => <div key={record.key}>{record.unit}</div>
            }
          ];
        }
        const { length: columnsLength } = columns;
        columns = columns.map((column) => {
          const { dataIndex } = column;

          if (dataIndex === 'inputAmount') {
            return column;
          }

          return {
            ...column,
            render: (text, record) =>
              renderContent(text, record, dataIndex, columnsLength)
          };
        });
      }

      setTableColumns(columns);
    };

    updateTableColumns();
  }, [userRole, tableType, setDate, setTableColumns, t]);

  return (
    <main className="app__main project-tree">
      <div className="project-tree__breadcrumb">
        {breadcrumbItems.length === 1 ? (
          <BackButton path="/all-projects" />
        ) : (
          <Button type="link" onClick={() => onGoBack()}>
            <ArrowLeftOutlined style={{ fontSize: 24 }} />
          </Button>
        )}

        <Breadcrumb style={{ fontSize: 24 }} separator=">">
          {breadcrumbItems.map((item) => (
            <Breadcrumb.Item key={item.text}>
              {item.type === 'normal' || item.type === 'category' ? (
                <FolderOpenTwoTone
                  style={{ fontSize: 24 }}
                  twoToneColor="#faad14"
                />
              ) : (
                <FileExcelTwoTone
                  style={{ fontSize: 24 }}
                  twoToneColor="#a0d911"
                />
              )}{' '}
              {item.text}
            </Breadcrumb.Item>
          ))}
        </Breadcrumb>
      </div>

      {isUser && (
        <div>
          <DatePicker
            defaultValue={moment()}
            allowClear={false}
            onChange={(value) => onDateChange(value)}
          />
        </div>
      )}

      {categories.length !== 0 ? (
        <Tabs
          style={{ width: '100vw' }}
          size="large"
          type="editable-card"
          animated
          hideAdd
          centered
          onChange={(activeKey) => onTabsChange(activeKey)}
          activeKey={tabActiveKey}
        >
          {categories.map((category, index) => (
            <TabPane
              tab={category.categoryKey}
              key={(index + 1).toString()}
              closable={false}
            >
              <Table
                key={category.categoryKey}
                form={form}
                rows={category.children}
                columns={getTableColumns()}
                loading={isFetching}
                isEditable
              />
            </TabPane>
          ))}
          {userRole === 'admin' && (
            <TabPane
              tab={<SettingOutlined />}
              key={categories.length + 1}
              closable={false}
              style={{
                display: 'flex',
                direction: 'column',
                justifyContent: 'center',
                alignItems: 'center'
              }}
            >
              <Form form={categoriesForm} onFinish={onSubmit}>
                {categories.map((category) => (
                  <Form.Item
                    key={category.categoryID}
                    label={category.categoryKey}
                    name={category.categoryID}
                    initialValue={category.categoryKey}
                    rules={[
                      {
                        required: true,
                        message: t('requiredField')
                      }
                    ]}
                  >
                    <Input type="text" placeholder={t('categoryName')} />
                  </Form.Item>
                ))}

                <Form.Item>
                  <Button
                    type="primary"
                    htmlType="submit"
                    style={{ display: 'block', margin: 'auto', width: 150 }}
                  >
                    {t('update')}
                  </Button>
                </Form.Item>
              </Form>
            </TabPane>
          )}
        </Tabs>
      ) : (
        <Table
          form={form}
          rows={data}
          columns={getTableColumns()}
          loading={isFetching}
          isEditable
        />
      )}
    </main>
  );
};

export default ProjectTree;
