/**
 * @category APIs
 * @module work-force
 * @version 1.0
 */
import axios from 'axios';

/**
 * Retrieves the workforce table records data
 *
 * @param {number} projectID - Project id in the database
 * @param {string} date - Search date
 * @returns {{status: number,
 * data: {notes: string,
 * categoryOptions: Array.<{key: number, label: string}>,
 * tableData: Array.<{key: string, category: string, categoryKey: number,
 * number: number, hours: number, dayExtraHours: number, nightExtraHours: number}>}}} Query result
 */
const getWorkForceEntries = async (projectID, date) => {
  try {
    return await axios.get('/entries', {
      params: {
        projectID,
        date
      },
      withCredentials: true
    });
  } catch ({ response }) {
    return response;
  }
};

/**
 *
 * @param {object} data - Table rows data
 * @param {number} data.projectID - Project id in the database
 * @param {string} data.date - Search date
 * @param {string} data.notes - Notes
 * @param {Array.<{category: number, number: number, hours: number,
 * dayExtraHours: number, nightExtraHours: number}>} data.data - Updated table rows data
 * @returns {{status: number, data: {message: string}}} Query result
 */
const updateWorkForceEntries = async (data) => {
  try {
    return await axios.post('/entries', data, {
      withCredentials: true
    });
  } catch ({ response }) {
    return response;
  }
};

export { getWorkForceEntries, updateWorkForceEntries };
