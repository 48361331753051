import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { CookiesProvider } from 'react-cookie';
import App from './components/App';
import Spinner from './components/Spinner';
import './i18n';

/**
 * Web app entry point
 */
ReactDOM.render(
  <React.StrictMode>
    <CookiesProvider>
      <Suspense fallback={<Spinner />}>
        <App />
      </Suspense>
    </CookiesProvider>
  </React.StrictMode>,
  document.getElementById('app')
);
