import React, { useState, useEffect, useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import {
  DatePicker,
  Typography,
  Popover,
  Select,
  List,
  Divider,
  Form,
  Button,
  Radio
} from 'antd';
import {
  ProfileTwoTone,
  ClockCircleOutlined,
  PayCircleOutlined,
  RocketOutlined,
  TagOutlined,
  TeamOutlined
} from '@ant-design/icons';
import Spinner from '../components/Spinner';
import Notification from '../components/Notification';
import BackButton from '../components/BackButton';
import MultiLineChart from '../components/MultiLineChart';
import Table from '../components/Table';
import PieChart from '../components/PieChart';
import useSafeState from '../hooks/useSafeState';
import useTable from '../hooks/useTable';
import { getTablesData, getChartsData } from '../api/reports';

const { Title, Text } = Typography;
const { Option } = Select;

const kellyColors = [
  '#F3C300',
  '#875692',
  '#F38400',
  '#A1CAF1',
  '#BE0032',
  '#C2B280',
  '#848482',
  '#008856',
  '#E68FAC',
  '#0067A5',
  '#F99379',
  '#604E97',
  '#F6A600',
  '#B3446C',
  '#DCD300',
  '#882D17',
  '#8DB600',
  '#654522',
  '#E25822',
  '#2B3D26',
  '#808a93',
  '#222222'
];

let testCost = 0;
let testActualTotal = 0;

/**
 * Project reports page where the user can display all the details about the project
 * using different charts and tables
 *
 * @category Routes
 * @component
 * @version 1.0
 */
const Reports = () => {
  const [dailyViewOptions, setDailyViewOptions] = useState('day');
  const [isFetching, setIsFetching] = useSafeState(false);
  const [reportSummary, setReportSummary] = useSafeState([]);
  const [view, setView] = useState('daily');
  const [multiLineChartData, setMultiLineChartData] = useSafeState({});
  const [pieChartData, setPieChartData] = useSafeState([]);
  const [workforceTableColumns, setWorkforceTableColumns] = useState([]);
  const { data: reportsTableData, setData: setReportsTableData } = useTable([]);
  const { data: workforceTableData, setData: setWorkforceTableData } = useTable(
    []
  );
  const [tableSummary, setTableSummary] = useSafeState({});
  const {
    state: { number: projectNumber, id: projectID }
  } = useLocation();
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const pieChartsTitles = [t('workers'), t('hoursBreakdown'), t('cost')];
  const reportsTableColumns = [
    {
      title: t('day'),
      dataIndex: 'date'
    },
    {
      title: t('workName'),
      dataIndex: 'workName'
    },
    {
      title: t('actualUnitPrice'),
      dataIndex: 'actualUnitPrice'
    },
    {
      title: t('actualUnitCount'),
      dataIndex: 'actualUnitCount'
    },
    {
      title: t('actualTotal'),
      dataIndex: 'actualTotal'
    }
  ];

  /**
   * Updates pie and multi-line charts data
   * - Sends an API request to the backend by passing the required query parameters
   * - The endDate property is only valid for a non-daily view type
   * - Updates pie chart data
   * - Update multi-line chart and it's summary description data
   *
   * @param {object} queryParams - Query parameters
   * @param {number} queryParams.projectID - Project code number
   * @param {string} queryParams.view - View type (Daily, Monthly or Yearly)
   * @param {string} queryParams.startDate - Start date
   * @param {string} queryParams.endDate - End date
   */
  const updateChartsData = async (queryParams) => {
    const response = await getChartsData(queryParams);
    console.info(queryParams);
    const { status } = response;

    if (status === 200) {
      const {
        data: { line, pie, summaryData }
      } = response;
      console.info(summaryData);
      const { labels: multiLineLabels, datasets: multiLineDatasets } = line;
      const multiLine = { labels: multiLineLabels, datasets: [] };
      const multiLineChartStyleDefaults = {
        fill: false,
        lineTension: 0.1,
        borderCapStyle: 'butt',
        borderDash: [],
        borderDashOffset: 0.0,
        borderJoinStyle: 'miter',
        pointBorderWidth: 1,
        pointHoverRadius: 5,
        pointHoverBorderWidth: 2,
        pointRadius: 1,
        pointHitRadius: 10
      };
      const { labels: pieChartLabels, datasets: pieChartDatasets } = pie;
      const pieCharts = [];

      for (let i = 0; i < multiLineDatasets.length; i += 1) {
        const colorIndex = 15 + i;

        multiLine.datasets.push({
          ...multiLineDatasets[i],
          label: t(multiLineDatasets[i].label),

          ...multiLineChartStyleDefaults,
          backgroundColor: kellyColors[colorIndex],
          borderColor: kellyColors[colorIndex],
          pointBorderColor: kellyColors[colorIndex],
          pointHoverBackgroundColor: kellyColors[colorIndex],
          pointHoverBorderColor: kellyColors[colorIndex],
          pointBackgroundColor: kellyColors[colorIndex]
        });
      }

      for (let i = 0; i < pieChartDatasets.length; i += 1) {
        pieCharts.push({
          labels: pieChartLabels[i].label,
          datasets: [
            {
              data: pieChartDatasets[i].data,
              backgroundColor: kellyColors.slice(
                0,
                pieChartDatasets[i].data.length
              ),
              hoverBackgroundColor: kellyColors.slice(
                0,
                pieChartDatasets[i].data.length
              )
            }
          ]
        });
      }

      const {
        budget,
        externalBudget,
        actualTotal,
        cost,
        actualTotalPercentage,
        costPercentage,
        profit,
        profitPercentage,
        orderId,
        clientOrderId
      } = summaryData;

      const reportSummaryList = [
        `${t('budget')}: ${budget}`,
        `${t('externalBudget')}: ${externalBudget}`,
        `${t('actualTotal')}: ¥ ${actualTotal} (${actualTotalPercentage}%)`,
        `${t('cost')}: ¥ ${cost} (${costPercentage}%)`,
        `${t('profit')}: ¥ ${profit} (${profitPercentage}%)`,
        `${t('orderID')}: ${orderId !== null ? orderId : ''}`,
        `${t('clientOrderID')}: ${clientOrderId !== null ? clientOrderId : ''}`
      ];

      setMultiLineChartData(multiLine);
      setPieChartData(pieCharts);
      setReportSummary(reportSummaryList);
    } else {
      Notification('error', t('error'), t('serverDownMessage'));
    }
  };

  /**
   * updateChartsData function callback reference
   */
  const updateChartsDataCB = useCallback(updateChartsData, [t]);

  /**
   * Updates table rows data
   * - Sends an API request to the backend by passing the required query parameters
   * - The endDate property is only valid for a non-daily view type
   * - Updates reports and workforce tables rows data
   * - Updates reports and workforce row summaries
   *
   * @param {object} queryParams - Query parameters
   * @param {number} queryParams.projectID - Project code number
   * @param {string} queryParams.view - View type (Daily, Monthly or Yearly)
   * @param {string} queryParams.startDate - Start date
   * @param {string} queryParams.endDate - End date
   */
  const updateTablesData = async (queryParams) => {
    const response = await getTablesData(queryParams);
    const { status } = response;

    if (status === 200) {
      const {
        data: {
          workTableData: reportsData,
          workforceTableData: workforceData,
          totalCostWork,
          totalUnits,
          totalCostWorkers,
          totalHoursWorkers
        }
      } = response;

      setReportsTableData(reportsData);
      setWorkforceTableData(workforceData);
      setTableSummary({
        totalCostWork,
        totalUnits,
        totalCostWorkers,
        totalHoursWorkers
      });
    } else {
      Notification('error', t('error'), t('serverDownMessage'));
    }
  };

  /**
   * updateTablesData function callback reference
   */
  const updateTablesDataCB = useCallback(updateTablesData, [
    setReportsTableData,
    setWorkforceTableData,
    t
  ]);

  /**
   * Handles the view type change.
   * - Updates the datepicker values to singular on daily view type.
   * - Updates the datepicker values to ranged on a non-daily view type.
   * - Updates daily view options to 'day'.
   * - Update view type to whatever the user selected.
   *
   * @param {string} value - Selected option value
   */
  const onSelect = (value) => {
    if (value !== 'daily') {
      form.setFieldsValue({ date: [moment(), moment()] });
      setDailyViewOptions('day');
    } else {
      form.setFieldsValue({ date: moment() });
    }
    setView(value);
  };

  /**
   * Creates DatePicker component based on the type parameter and
   * whether it supports range selection or not
   *
   * @param {string} type - DatePicker type
   * @param {boolean} isRanged
   * @returns {jsx} Antd DatePicker component
   */
  const createDatePicker = (type, isRanged = false) => {
    let placeholder;

    if (type === 'date') {
      placeholder = [t('startDate'), t('endDate')];
    } else if (type === 'month') {
      placeholder = [t('startMonth'), t('endMonth')];
    } else {
      placeholder = [t('startYear'), t('endYear')];
    }

    if (!isRanged) {
      return <DatePicker allowClear={false} size="large" />;
    }

    return (
      <DatePicker.RangePicker
        allowClear={false}
        size="large"
        picker={type}
        placeholder={placeholder}
      />
    );
  };

  /**
   * Gets the appropriate DatePicker component based on the view state
   *
   * @returns {jsx} Antd DatePicker component
   */
  const getDatePikcer = () => {
    if (view === 'daily') {
      if (dailyViewOptions === 'day') {
        return createDatePicker('date');
      }
      return createDatePicker('date', true);
    }
    if (view === 'monthly') {
      return createDatePicker('month', true);
    }
    return createDatePicker('year', true);
  };

  /**
   * Handles the radio buttons change logic.
   * - If the user chose the 'day' option the default value becomes today's date moment.
   * - If the user chose the 'duration' option, The default value of the ranged values
   * become today's date moment.
   * - Updates the daily view options
   *
   * @param {object} event - Synthetic event
   * @param {string} event.target.value - User choice value
   */
  const onRadioButtonsChange = ({ target: { value } }) => {
    if (value === 'day') {
      form.setFieldsValue({ date: moment() });
    } else {
      form.setFieldsValue({ date: [moment(), moment()] });
    }
    setDailyViewOptions(value);
  };

  /**
   * Handles date form submission logic.
   * - Sends an API request to the backend with the required data to update
   * both charts and tables data.
   *
   * @param {object} values - User inputs
   * @param {Array.<object>} values.date - Date moment
   * @param {string} values.viewType - View type
   */
  const onSubmit = async (values) => {
    const { date, viewType } = values;

    if (date && viewType) {
      let dateQueryParams;

      if (!Array.isArray(date)) {
        dateQueryParams = { startDate: date.format('YYYY-MM-DD') };
      } else {
        const [startDate, endDate] = date;
        dateQueryParams = {
          startDate: startDate.format('YYYY-MM-DD'),
          endDate: endDate.format('YYYY-MM-DD')
        };
      }

      console.info(date);

      const queryParams = {
        projectID,
        ...dateQueryParams,
        viewType
      };



      setIsFetching(true);

      await updateChartsDataCB(queryParams);
      await updateTablesDataCB(queryParams);

      setIsFetching(false);
    }
  };

  /**
   * Creates a summary row component for the table.
   *
   * @param {string} keyword - Title translation keyword
   * @param {string} value - Value
   * @returns {jsx} Text row component
   */
  const getTableSummaryRow = (keyword, value) => (
    <Text style={{ float: 'right', margin: '5px 50px 0 0' }}>
      <b>{t(keyword)}: </b>
      {value}
    </Text>
  );

  /**
   * Updates both charts and tables data
   */
  useEffect(() => {
    /**
     * Updates multi-line, pie and tables data by sending API request to the backend with the required data.
     * @memberof Reports
     */
    const updateData = async () => {
      const queryParams = {
        projectID,
        startDate: moment().format('YYYY-MM-DD'),
        view: 'daily'
      };

      setIsFetching(true);

      await updateChartsDataCB(queryParams);
      await updateTablesDataCB(queryParams);

      setIsFetching(false);
    };

    updateData();
  }, [projectID, setIsFetching, updateChartsDataCB, updateTablesDataCB]);

  /**
   * Updates workforce table columns data
   */
  useEffect(() => {
    /**
     * Reset charts and tables data
     *
     * @memberof Reports
     */
    const resetStates = () => {
      setMultiLineChartData({});
      setReportSummary([]);
      setReportsTableData([]);
      setWorkforceTableData([]);
      setTableSummary([]);
      setPieChartData([]);
    };

    /**
     * Creates a List component
     *
     * @memberof Reports
     * @param {object[]} categories - Categories data
     * @param {string} categories.class - Class name
     * @param {string} categories.number - Number of workers
     * @param {string} categories.hours - Number of hours
     * @param {string} categories.cost - Cost value
     * @returns {jsx} List component
     */
    const getCategoryBreakdownData = (categories) => (
      <List
        size="large"
        header={
          <div>
            <ProfileTwoTone /> {t('breakdown')}
          </div>
        }
        locale={{ emptyText: t('noData') }}
        bordered
        dataSource={categories}
        renderItem={(item) => (
          <List.Item>
            <b>{t('category')}</b>: {item.class} &emsp;
            <TeamOutlined /> <b>{t('numberBreakdown')}</b>: {item.number} &emsp;
            <ClockCircleOutlined /> <b>{t('hoursBreakdown')}</b>: {item.hours}{' '}
            &emsp;
            <PayCircleOutlined /> <b>{t('cost')}</b>: {item.cost}
          </List.Item>
        )}
      />
    );

    /**
     * Updates workforce table columns based on the type of selected view
     */
    const updateWorkforceTableColumns = () => {
      const commonColumns = [
        {
          title: t('numberBreakdown'),
          dataIndex: 'numberPerCategory'
        },
        {
          title: t('hoursBreakdown'),
          dataIndex: 'hoursPerCategory'
        },
        {
          title: t('cost'),
          dataIndex: 'costPerCategory'
        }
      ];
      const dayColumn = {
        title: t('day'),
        dataIndex: 'day'
      };
      let columns;

      if (view === 'daily') {
        const categoryColumn = {
          title: t('category'),
          dataIndex: 'category'
        };
        columns = [
          dailyViewOptions === 'duration' ? dayColumn : categoryColumn,
          ...commonColumns
        ];
      } else if (view === 'monthly') {
        columns = [dayColumn, ...commonColumns];
      } else {
        columns = [
          {
            title: t('month'),
            dataIndex: 'month'
          },
          ...commonColumns
        ];
      }

      const mergedColumns = columns.map((column) => {
        const { dataIndex } = column;
        const isBreakable =
          dataIndex === 'category' ||
          dataIndex === 'day' ||
          dataIndex === 'month';
        if (isBreakable) {
          return {
            ...column,
            render: (text, record) => {
              return (
                <Popover
                  content={getCategoryBreakdownData(record.categoryBreakdown)}
                >
                  {text}
                </Popover>
              );
            }
          };
        }
        return { ...column };
      });

      setWorkforceTableColumns(mergedColumns);
    };

    resetStates();
    updateWorkforceTableColumns();
  }, [
    view,
    dailyViewOptions,
    setMultiLineChartData,
    setPieChartData,
    setReportSummary,
    setReportsTableData,
    setWorkforceTableData,
    setTableSummary,
    t
  ]);

  return (
    <main className="app__main reports">
      {isFetching ? (
        <Spinner />
      ) : (
        <>
          <div className="reports__controls">
            <Form
              className="reports__form"
              layout="inline"
              form={form}
              initialValues={{ date: moment() }}
              onFinish={onSubmit}
            >
              <Form.Item>
                <div
                  style={{
                    display: 'flex',
                    flexFlow: 'row nowrap',
                    justifyContent: 'center',
                    alignItems: 'center'
                  }}
                >
                  <BackButton path="/all-projects" />

                  <Title style={{ marginTop: 8 }} level={4}>
                    <TagOutlined style={{ color: '#faad14' }} />
                    {projectNumber}
                  </Title>
                </div>
              </Form.Item>

              <Form.Item name="date">{getDatePikcer()}</Form.Item>

              <Form.Item name="viewType" initialValue="daily">
                <Select size="large" value={view} onChange={onSelect}>
                  <Option value="daily">{t('daily')}</Option>
                  <Option value="monthly">{t('monthly')}</Option>
                  <Option value="yearly">{t('yearly')}</Option>
                </Select>
              </Form.Item>

              {view === 'daily' && (
                <div className="form__radio-group">
                  <Radio.Group
                    defaultValue={dailyViewOptions}
                    onChange={onRadioButtonsChange}
                  >
                    <Radio value="day">{t('day')}</Radio>
                    <Radio value="duration">{t('duration')}</Radio>
                  </Radio.Group>
                </div>
              )}

              <Form.Item>
                <Button
                  type="primary"
                  size="large"
                  icon={<RocketOutlined />}
                  htmlType="submit"
                >
                  {t('update')}
                </Button>
              </Form.Item>
            </Form>
          </div>

          <Divider orientation="left">
            <Title level={3}>{t('projectSummary')}</Title>
          </Divider>

          <div className="reports__multi-line-chart">
            <MultiLineChart data={multiLineChartData} />
          </div>

          <div className="reports__report-details">
            <List
              size="large"
              locale={{ emptyText: t('noData') }}
              bordered
              dataSource={reportSummary}
              renderItem={(item) => (
                <List.Item>
                  <PayCircleOutlined
                    style={{ fontSize: 16, color: '#faad14' }}
                  />{' '}
                  {item}
                </List.Item>
              )}
            />
          </div>

          <div className="reports__table">
            <Divider orientation="left">
              <Title level={3}>{t('workSummary')}</Title>
            </Divider>

            <Table
              rows={reportsTableData}
              columns={reportsTableColumns}
              loading={isFetching}
              isEditable={false}
            />

            {tableSummary.length !== 0 && (
              <>
                {getTableSummaryRow('actualTotal', tableSummary.totalCostWork)}
                {getTableSummaryRow('totalUnits', tableSummary.totalUnits)}
              </>
            )}
          </div>

          <div className="reports__work-force-table">
            <Divider orientation="left">
              <Title level={3}>{t('laborSummary')}</Title>
            </Divider>

            <Table
              rows={workforceTableData}
              columns={workforceTableColumns}
              loading={isFetching}
              isEditable={false}
            />

            {tableSummary.length !== 0 && (
              <>
                {getTableSummaryRow('cost', tableSummary.totalCostWorkers)}
                {getTableSummaryRow(
                  'totalHours',
                  tableSummary.totalHoursWorkers
                )}
              </>
            )}
          </div>

          {pieChartData.length > 0 && (
            <>
              <Divider orientation="left">
                <Title level={3}>{t('workforceBreakdown')}</Title>
              </Divider>

              <div className="reports__work-force-pie-chart">
                {pieChartData.map((data, index) => (
                  <div key={pieChartsTitles[index]}>
                    <Title
                      style={{ textAlign: 'center', marginRight: 25 }}
                      level={4}
                    >
                      {pieChartsTitles[index]}
                    </Title>
                    <PieChart data={data} />
                  </div>
                ))}
              </div>
            </>
          )}
        </>
      )}
    </main>
  );
};

export default Reports;
