/**
 * @category APIs
 * @module update-credentials
 * @version 1.0
 */
import axios from 'axios';

/**
 * Updates user account credentials
 *
 * @param {object} data - User credentials
 * @param {string} data.username - New username
 * @param {string} data.oldPassword - Old password
 * @param {string} data.password - New password
 * @returns {{status: number, data: {message: string}}} Query result
 */
const updateCredentialsInfo = async (data) => {
  try {
    return await axios.patch('/users', data, {
      withCredentials: true
    });
  } catch ({ response }) {
    return response;
  }
};

export default updateCredentialsInfo;
