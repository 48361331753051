import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Table as AntdTable, Form } from 'antd';
import EditableCell from './EditableCell';

/**
 * A table that displays rows of data.
 *
 * @component
 * @version 1.0
 */
const Table = ({ form, rows, columns, loading, isEditable }) => {
  const { t } = useTranslation();
  const scrollStyle = {};
  const viewportWidth = window.innerWidth;

  if (viewportWidth < 500) {
    scrollStyle.x = 300;
  }
  if (rows?.length > 4) {
    scrollStyle.y = 300;
  }

  return (
    <Form form={form} component={false}>
      <AntdTable
        style={{ width: '98vw' }}
        size="large"
        bordered
        loading={loading}
        columns={columns.map((column) => ({ ...column, align: 'center' }))}
        dataSource={rows}
        components={
          isEditable && {
            body: {
              cell: EditableCell
            }
          }
        }
        pagination={{
          position: ['bottomCenter'],
          hideOnSinglePage: true
        }}
        scroll={scrollStyle}
        locale={{ emptyText: t('noData') }}
      />
    </Form>
  );
};

Table.propTypes = {
  /**
   * Form control instance created by Form.useForm().
   * - Check offical docs for all supported functions: https://ant.design/components/form/#FormInstance
   */
  form: PropTypes.object,
  /**
   * Table rows data
   * - Row's object shape varies based on usage the only required property is a unique key.
   * - Row's object properties' names should match the columns' dataIndex field
   */
  rows: PropTypes.arrayOf(PropTypes.object).isRequired,
  /**
   * Table columns data
   * - Column object shape varies based on usage check the official docs: https://ant.design/components/table/#Column
   */
  columns: PropTypes.arrayOf(PropTypes.shape).isRequired,
  /** Table loading status */
  loading: PropTypes.bool.isRequired,
  /** Whether the table has editable rows or not */
  isEditable: PropTypes.bool.isRequired
};

Table.defaultProps = {
  form: null
};

export default Table;
