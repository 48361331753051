import { notification } from 'antd';

/**
 * Custom notifications component
 *
 * @version 1.0
 * @param {string} type - Notification type (Either 'success' or 'error')
 * @param {string} message - Notification title
 * @param {string} description - Notification description
 */
const Notification = (type, message, description) => {
  notification[type]({
    message,
    description
  });
};

export default Notification;
