import React from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Notification from '../components/Notification';
import BackButton from '../components/BackButton';
import ProjectForm from '../components/ProjectForm';
import { createProject } from '../api/projects';

/**
 * Create and Add a brand new project to the projects table route
 *
 * @category Routes
 * @component
 * @version 1.0
 */

const AddProject = () => {
  const history = useHistory();
  const { t } = useTranslation();

  /**
   * Handles the form submition logic
   * - Sends an API request with the collected user inputs to the backend for project creation
   * - Redirects the user to all-projects route
   * - Notifies the user whether the creation request was successful or failure
   *
   * @param {object} values User inputs
   * @param {string} values.name Project name
   * @param {string} values.orderSheet Order Id
   * @param {string} values.customerOrderSheet Client order Id
   * @param {string} values.budget Project budget
   * @param {string} values.externalBudget Project external budget
   * @param {number} values.status Project status code
   * @param {number} values.type Project type code
   * @param {Array.<{category: string, number: string, hours: string}>} values.categories Project work force categories
   */
  const onSubmit = async (values) => {
    const formData = { ...values, budget: Number(values.budget) };
    const { status } = await createProject(formData);

    if (status === 201) {
      Notification('success', t('submissionSucessful'), t('projectCreated'));
      history.push('/all-projects');
    } else {
      Notification('error', t('submissionFailed'), t('somethingWrong'));
    }
  };

  return (
    <main className="app__main add-project">
      <BackButton path="/dashboard" />

      <div className="add-project__form">
        <ProjectForm onSubmit={onSubmit} />
      </div>
    </main>
  );
};

export default AddProject;
