import React from 'react';
import { useHistory, Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Button } from 'antd';
import { ArrowLeftOutlined } from '@ant-design/icons';
import CredentialsForm from '../components/CredentialsForm';
import Notification from '../components/Notification';
import updateCredentialsInfo from '../api/update-credentials';

/**
 * Updates admin and user credentials page (Accessible only by the admin)
 *
 * @category Routes
 * @component
 * @version 1.0
 */
const UpdateCredentials = () => {
  const history = useHistory();
  const { t } = useTranslation();
  const formItemLayout = {
    labelCol: {
      span: 10
    },
    wrapperCol: {
      span: 14
    }
  };
  const submitButtonLayout = {
    wrapperCol: {
      xs: {
        span: 24,
        offset: 0
      },
      sm: {
        span: 16,
        offset: 8
      }
    }
  };

  /**
   * Handles the update process
   * - Sends an API request to the backend with the new credentials
   * - On success notifies the user then redirects him to the dashboard page
   * - On failure notifies the user to try again
   *
   * @param {object} values - User new credentials
   * @param {string} values.username - Username
   * @param {string} values.oldPassword - Current password
   * @param {string} values.password - New password
   * @param {string} values.confirmedPassword - New password confirmation
   */
  const onUpdate = async (values) => {
    const { confirmedPassword, ...data } = values;
    const { status } = await updateCredentialsInfo(data);

    if (status === 200) {
      Notification('success', t('great'), t('updatesSaved'));
      history.push('/dashboard');
    } else {
      Notification('error', t('error'), t('serverDownMessage'));
    }
  };

  return (
    <main className="app__main update-credentials">
      <Button type="link">
        <Link to="/dashboard">
          <ArrowLeftOutlined style={{ fontSize: 24 }} />
        </Link>
      </Button>

      <CredentialsForm
        formItemLayout={formItemLayout}
        submitButtonLayout={submitButtonLayout}
        onSubmit={onUpdate}
      />
    </main>
  );
};

export default UpdateCredentials;
