import React from 'react';
import PropTypes from 'prop-types';
import { Pie } from 'react-chartjs-2';

/**
 * Pie chart component
 *
 * @component
 * @version 2.0
 */
const PieChart = ({ data }) => {
  const legend = {
    display: true,
    position: 'right',
    fullWidth: false,
    reverse: false,
    labels: {
      fontColor: 'rgb(0, 0, 0)'
    }
  };

  const options = {
    responsive: false,
    maintainAspectRatio: true
  };

  return (
    <Pie
      width={500}
      height={500}
      data={data}
      legend={legend}
      options={options}
    />
  );
};

PieChart.propTypes = {
  /** Restructured data */
  data: PropTypes.shape({
    /** Legends' labels */
    labels: PropTypes.arrayOf(PropTypes.string),
    /** Slices' data */
    datasets: PropTypes.arrayOf(
      PropTypes.shape({
        /** Pie number N data */
        data: PropTypes.arrayOf(PropTypes.number),
        /** Slices' colors */
        backgroundColor: PropTypes.arrayOf(PropTypes.string)
      })
    )
  }).isRequired
};

export default PieChart;
