import React from 'react';
import { Line } from 'react-chartjs-2';
import PropTypes from 'prop-types';

/**
 * Multi-line chart component
 *
 * @component
 * @version 1.0
 */
const MultiLineChart = ({ data }) => {
  const legend = {
    display: true,
    position: 'right',
    fullWidth: true,
    reverse: false,
    labels: {
      fontColor: 'rgb(0, 0, 0)'
    }
  };

  const options = {
    responsive: false,
    maintainAspectRatio: false,
    animation: false
  };

  return (
    <Line
      width={800}
      height={400}
      data={data}
      legend={legend}
      options={options}
    />
  );
};

MultiLineChart.propTypes = {
  /** Line(s) data */
  data: PropTypes.shape({
    /** X-axis' labels */
    labels: PropTypes.arrayOf(PropTypes.string),
    /** Cost over time data */
    datasets: PropTypes.arrayOf(
      PropTypes.shape({
        /** Legends labels */
        label: PropTypes.string,
        /** Line number N data */
        data: PropTypes.arrayOf(PropTypes.number),
        /** Line HEX color */
        borderColor: PropTypes.string,
        /** Line point HEX color */
        pointBorderColor: PropTypes.string,
        /**
         * The following props are provided in the react-chartjs-2 library's example
         * here: https://github.com/jerairrest/react-chartjs-2/blob/master/example/src/components/line.js
         */
        fill: PropTypes.bool,
        lineTension: PropTypes.number,
        borderCapStyle: PropTypes.string,
        borderDash: PropTypes.arrayOf(PropTypes.number),
        borderDashOffset: PropTypes.number,
        borderJoinStyle: PropTypes.string,
        pointBackgroundColor: PropTypes.string,
        pointBorderWidth: PropTypes.number,
        pointHoverRadius: PropTypes.number,
        pointHoverBackgroundColor: PropTypes.string,
        pointHoverBorderColor: PropTypes.string,
        pointHoverBorderWidth: PropTypes.number,
        pointRadius: PropTypes.number,
        pointHitRadius: PropTypes.number
      })
    )
  }).isRequired
};

export default MultiLineChart;
