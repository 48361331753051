import i18n from 'i18next';
import Backend from 'i18next-xhr-backend';
import { initReactI18next } from 'react-i18next';

/**
 * i18n's environment setup file
 */
i18n
  .use(Backend)
  .use(initReactI18next)
  .init({
    lng: 'ja',
    backend: {
      loadPath: '/{{ns}}/{{lng}}.json'
    },
    fallbackLng: 'ja',
    debug: false,
    ns: ['languages'],
    defaultNS: 'languages',
    keySeparator: false,
    interpolation: {
      escapeValue: false,
      formatSeparator: ','
    },
    react: {
      wait: true,
      useSuspense: true
    }
  });

export default i18n;
