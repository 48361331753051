/**
 * @category APIs
 * @module project-tree
 * @version 1.0
 */
import axios from 'axios';

/**
 * Retrieves the project tree list data
 *
 * @param {object} params - Query params
 * @param {number} params.projectid - Project id in the database
 * @param {number} params.parent - Parent sub project id in the database
 * @param {string} params.date - Selected date (Available only for non-admin accounts)
 * @returns {{status: number, data: {projectType: number, type: string, data: object[]}}} Query result
 */
const getProjectTree = async (params) => {
  try {
    return await axios.get('/layers', {
      params,
      withCredentials: true
    });
  } catch ({ response }) {
    return response;
  }
};

/**
 * Updates project tree categories' names
 *
 * @param {object[]} data - Categories data
 * @param {number} data.id - Category id in the database
 * @param {string} data.specification - Category specification
 * @returns {{status: number, data: {layers: Array.<{id: number, specification: string}>}}} Query result
 */
const updateCategoriesNames = async (data) => {
  try {
    return await axios.patch(
      `/layers`,
      {
        layers: data
      },
      {
        withCredentials: true
      }
    );
  } catch ({ response }) {
    return response;
  }
};

/**
 * Updates the table row's data
 *
 * @param {number} id - Row id in the database
 * @param {object} data - Edited row values
 * @param {number} data.listedUnitPrice - Listed unit price
 * @param {number} data.listedUnitCount - Listed unit count
 * @param {number} data.otherUnitPrice - Other unit price
 * @param {number} data.otherUnitCount - Other unit count
 * @param {number} data.actualUnitCount - Actual unit count
 * @returns {{status: number,
 * data: {id: number, workName: string, unit: string,
 * listedUnitPrice: number, listedUnitCount: number, listedTotal: number,
 * otherUnitPrice: number, otherUnitCount: number, otherTotal: number,
 * listedTotal: number, actualUnitCount: number, actualTotal: number,
 * costDifferenceJPY: number, costDifferencePercentage: number}}} Query result
 */
const updateWorkData = async (id, data) => {
  try {
    return await axios.patch(`/work/${id}`, data, {
      withCredentials: true
    });
  } catch ({ response }) {
    return response;
  }
};

export { getProjectTree, updateCategoriesNames, updateWorkData };
