import React from 'react';
import { css } from '@emotion/core';
import ClimbingBoxLoader from 'react-spinners/ClimbingBoxLoader';

/**
 * Loading spinner components
 *
 * @component
 * @version 1.0
 */
const Spinner = () => {
  const styles = css`
    position: fixed;
    top: 45%;
    left: 50%;
  `;

  return <ClimbingBoxLoader css={styles} size={30} color="#1890ff" />;
};

export default Spinner;
