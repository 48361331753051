import React from 'react';
import { Route, Redirect } from 'react-router-dom';

/**
 * Routes that are not available to the non-admin accounts
 *
 * @category Routes
 * @component
 * @version 1.0
 */

const ProtectedRoute = ({ component: Component, ...routeProps }) => {
  const userRole = sessionStorage.getItem('role');
  const userIsAuth = sessionStorage.getItem('isAuth');
  const {
    location: { pathname, state }
  } = routeProps;
  const privateRoutes = [
    'dashboard',
    'update-credentials',
    'edit-project',
    'reports'
  ];
  const isPrivateRoute = privateRoutes.some((route) =>
    pathname.includes(route)
  );
  const dependentRoutes = [
    'edit-project',
    'work-force',
    'project-tree',
    'reports'
  ];
  const isDependentRoute = dependentRoutes.some((route) => {
    const regex = `.*/${route}/.*`;
    return new RegExp(regex, 'g').test(pathname);
  });

  return (
    <Route
      {...routeProps}
      render={(props) => {
        if (isDependentRoute && !state) {
          return <Redirect to="/all-projects" />;
        }
        if (userIsAuth && userRole !== 'admin' && isPrivateRoute) {
          return <Redirect to="/not-authorized" />;
        }
        if (userIsAuth) {
          return <Component {...props} />;
        }
        return <Redirect to="/not-authorized" />;
      }}
    />
  );
};

export default ProtectedRoute;
