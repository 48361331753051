/**
 * @category APIs
 * @module login
 * @version 1.0
 */
import axios from 'axios';

/**
 * Handles user login query
 *
 * @param {object} userCredentials - User credentials
 * @param {string} userCredentials.username - Username
 * @param {string} userCredentials.password - Password
 * @returns {{status: number, data: {roles: Array.<string>, token: string }}} Query result
 */
const login = async (userCredentials) => {
  try {
    return await axios.post('/users/signin', userCredentials);
  } catch ({ response }) {
    return response;
  }
};

export default login;
