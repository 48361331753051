import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { v4 as uuidv4 } from 'uuid';
import { Form, Input, DatePicker, Button, Typography } from 'antd';
import { TagOutlined, PlusOutlined, RocketOutlined } from '@ant-design/icons';
import BackButton from '../components/BackButton';
import Notification from '../components/Notification';
import Table from '../components/Table';
import useSafeState from '../hooks/useSafeState';
import useTable from '../hooks/useTable';
import { getWorkForceEntries, updateWorkForceEntries } from '../api/work-force';

/**
 * Workforce page responsible for displaying detailed information about all the workforces data,
 * Admin & User can modify their work related data and leave a note.
 *
 * @category Routes
 * @component
 * @version 1.0
 */
const Workforce = () => {
  const [isFetching, setIsFetching] = useSafeState(false);
  const [isModified, setIsModified] = useSafeState(false);
  const [date, setDate] = useState(moment().format('YYYY-MM-DD'));
  const [categoryOptions, setCategoryOptions] = useSafeState([]);
  const {
    state: { number: projectNumber, id: projectID }
  } = useLocation();
  const { t } = useTranslation();
  const [queryForm] = Form.useForm();
  const { form, data, setData, isEditing, edit, cancel, save } = useTable([]);

  /**
   * Creates the required component code to be shown inside the corresponding cell
   * based on the table column index
   *
   * @param {string} dataIndex - Table column index
   * @returns {string} Component code
   */
  const getCellType = (dataIndex) => {
    if (dataIndex === 'category') {
      return 'select';
    }
    if (dataIndex === 'operations') {
      return 'button-group';
    }
    return 'number';
  };

  /**
   * Creates table columns based on the logged in user's role
   *
   * @returns {Array.<{title: string, dataIndex: string,
   * editable: boolean, render: Function, onCell: Function}>} Table columns
   */
  const getTableColumns = () => {
    const columns = [
      {
        title: t('category'),
        dataIndex: 'category',
        editable: true,
        render: (_, record) =>
          categoryOptions.find(
            ({ key, label }) =>
              key === record.category || label === record.category
          )?.label
      },
      {
        title: t('numberBreakdown'),
        dataIndex: 'number',
        editable: true
      },
      {
        title: t('hoursBreakdown'),
        dataIndex: 'hours',
        editable: true
      },
      {
        title: t('dayExtraHours'),
        dataIndex: 'dayExtraHours',
        editable: true
      },
      {
        title: t('nightExtraHours'),
        dataIndex: 'nightExtraHours',
        editable: true
      },
      {
        title: t('operations'),
        dataIndex: 'operations',
        editable: true,
        render: (_, record) => (
          <Button onClick={() => edit(record)}>{t('edit')}</Button>
        )
      }
    ];

    return columns.map((column) => {
      const { dataIndex, title } = column;

      return {
        ...column,
        onCell: (record) => ({
          record,
          selectOptions: categoryOptions,
          inputType: getCellType(dataIndex),
          dataIndex,
          title,
          editing: isEditing(record),
          onSave: () => {
            setIsModified(true);
            save({ key: record.key });
          },
          cancel
        })
      };
    });
  };

  /**
   * Cancels any opened row in edit mode.
   * Updates the date state value to what the user selected.
   *
   * @param {object} value - Date moment
   */
  const onDateChange = (value) => {
    cancel();
    setDate(value.format('YYYY-MM-DD'));
  };

  /**
   * Adds a new empty row to the table.
   * - Updates table rows data.
   * - Open the latest added row in edit mode.
   * - Updates isModified state to indicate that the user did some modifications.
   */
  const addRow = () => {
    setData((prevData) => [
      ...prevData,
      {
        key: uuidv4(),
        category: '',
        number: null,
        hours: null,
        dayExtraHours: null,
        nightExtraHours: null
      }
    ]);
    setIsModified(true);
  };

  /**
   * Saves user modifications in the database.
   * - Filters the empty data from the table.
   * -Sends an API request to the backend with the required data.
   * - On success notifies the user.
   * - On failure notifies the user to try again.
   *
   * @param {object} values - User inputs
   * @param {object} values.date - Date moment
   * @param {string} values.note - Note value
   */
  const onUpdate = async (values) => {
    if (values.date && data) {
      const { date: selectedDate, note } = values;
      const tableData = [];

      data.forEach((row) => {
        const { category, number, hours, dayExtraHours, nightExtraHours } = row;
        if (number || hours || dayExtraHours || nightExtraHours) {
          tableData.push({
            category: categoryOptions.find(
              ({ key, label }) => key === category || label === category
            ).key,
            number,
            hours,
            dayExtraHours,
            nightExtraHours
          });
        }
      });

      const query = {
        projectID,
        date: selectedDate.format('YYYY-MM-DD'),
        notes: note,
        data: tableData
      };
      const { status } = await updateWorkForceEntries(query);

      if (status === 201) {
        Notification('success', t('great'), t('updatesSaved'));
        setIsModified(false);
      } else {
        Notification('error', t('error'), t('serverDownMessage'));
      }
    }
  };

  /**
   * Updates the table rows data whenever the user selects a new date.
   */
  useEffect(() => {
    /**
     * Updates the table rows data.
     * - Sends an API request to the backend with the required data.
     * - Updates the note initial value in the query form to what was saved in the database.
     * - Update the table rows data and add unique key values.
     * - Updates the categories values.
     *
     * @memberof Workforce
     */
    const updateTableData = async () => {
      setIsFetching(true);

      const response = await getWorkForceEntries(projectID, date);
      const { status } = response;

      if (status === 200) {
        const {
          data: { tableData, notes, categoryOptions: categories }
        } = response;

        queryForm.setFieldsValue({ note: notes });
        setData(
          tableData.map((row) => ({
            ...row,
            key: uuidv4()
          }))
        );
        setCategoryOptions(categories);
        setIsFetching(false);
      } else {
        Notification('error', t('error'), t('serverDownMessage'));
      }
    };

    updateTableData();
  }, [
    projectID,
    queryForm,
    date,
    setIsFetching,
    setData,
    setCategoryOptions,
    t
  ]);

  return (
    <main className="app__main work-force">
      <Form form={queryForm} className="work-force__form" onFinish={onUpdate}>
        <Form.Item className="form__project-controls">
          <div
            style={{
              display: 'flex',
              flexFlow: 'row nowrap',
              justifyContent: 'center',
              alignItems: 'center'
            }}
          >
            <BackButton path="/all-projects" disabled={isModified} />

            <Typography.Title style={{ marginTop: 10 }} level={4}>
              <TagOutlined style={{ color: '#faad14' }} /> {projectNumber}
            </Typography.Title>
          </div>
        </Form.Item>

        <Form.Item name="date" initialValue={moment()}>
          <DatePicker
            disabled={isModified}
            size="large"
            allowClear={false}
            value={date}
            onChange={onDateChange}
            onClick={() =>
              isModified &&
              Notification('info', t('info'), t('updateFirstMessage'))
            }
          />
        </Form.Item>

        <Form.Item>
          <Button
            type="primary"
            size="large"
            icon={<PlusOutlined />}
            onClick={() => addRow()}
          >
            {t('add')}
          </Button>
        </Form.Item>

        <Form.Item>
          <Button
            disabled={!isModified}
            htmlType="submit"
            type="primary"
            size="large"
            icon={<RocketOutlined />}
          >
            {t('update')}
          </Button>
        </Form.Item>

        <Form.Item className="form__note-panel" label={t('note')} name="note">
          <Input
            type="text"
            size="large"
            onChange={() => setIsModified(true)}
          />
        </Form.Item>
      </Form>

      <div className="work-force__table">
        <Table
          form={form}
          rows={data}
          columns={getTableColumns()}
          loading={isFetching}
          isEditable
        />
      </div>
    </main>
  );
};

export default Workforce;
