/**
 * @category APIs
 * @module reports
 * @version 1.0
 */
import axios from 'axios';

/**
 * Retrieves the tables data
 *
 * @param {object} params - Query parameters
 * @param {number} params.projectID - Project Id in the database
 * @param {string} params.startDate - Search start date
 * @param {string} params.endDate - Search end date
 * @param {string} params.view - Default view type (daily)
 * @param {string} params.viewType - Selected view type (daily, monthly and yearly)
 * @returns {{status: number,
 * data: {workTableData: Array.<{key: string, date: string,workName: string,
 * actualUnitPrice: number, actualUnitCount: number, actualTotal: number}>,
 * workforceTableData: Array.<{key: string, day: string, month: string, category: string,
 * categoryBreakdown: Array.<{date: string, class: string, number: number, hours: number, cost: number}>,
 * numberPerCategory: string, hoursPerCategory: string, costPerCategory: string}>,
 * totalCostWork: string, totalCostWorkers: string,
 * totalHoursWorkers: number, totalUnits: number}}} Query result
 */
const getTablesData = async (params) => {
  try {
    return await axios.get('/work/table', {
      params,
      withCredentials: true
    });
  } catch ({ response }) {
    return response;
  }
};

/**
 * Retrieves the multi-line and pie charts data
 *
 * @param {object} params - Query parameters
 * @param {number} params.projectID - Project Id in the database
 * @param {string} params.startDate - Search start date
 * @param {string} params.endDate - Search end date
 * @param {string} params.view - Default view type (daily)
 * @param {string} params.viewType - Selected view type (daily, monthly and yearly)
 * @returns {{status: number, data: {line: object, pie: object,
 * summaryData: {budget: string, actualTotal: string, actualTotalPercentage: string,
 * profit: string, profitPercentage: string, orderId: string, clientOrderId: string}}}} Query result
 */
const getChartsData = async (params) => {
  try {
    return await axios.get('/projects/graph', {
      params,
      withCredentials: true
    });
  } catch ({ response }) {
    return response;
  }
};

export { getTablesData, getChartsData };
