import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Button } from 'antd';
import { ArrowLeftOutlined } from '@ant-design/icons';

/**
 * Navigation back button
 *
 * @component
 * @version 1.0
 */
const BackButton = ({ path, disabled }) => (
  <Button type="link" disabled={disabled}>
    <Link to={path}>
      <ArrowLeftOutlined style={{ fontSize: 24 }} />
    </Link>
  </Button>
);

BackButton.propTypes = {
  /** Navigation destination */
  path: PropTypes.string.isRequired,
  /** Whether the button is enabled or disabled */
  disabled: PropTypes.bool
};

BackButton.defaultProps = {
  disabled: false
};

export default BackButton;
