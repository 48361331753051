import React from 'react';
import { useLocation, useHistory, Link } from 'react-router-dom';
import { useCookies } from 'react-cookie';
import { useTranslation } from 'react-i18next';
import { Button } from 'antd';
import CompanyLogo from '../assets/images/company-logo.png';
import LanguageSelector from './LanguageSelector';

/**
 * Web app layout: Header component
 *
 * @component
 * @version 1.0
 */
const Header = () => {
  const { pathname } = useLocation();
  const history = useHistory();
  const [, , removeCookie] = useCookies(['token']);
  const { t } = useTranslation();
  const isProduction = process.env.NODE_ENV === 'production';
  const userRole = sessionStorage.getItem('role');

  /**
   * Handles user logout action.
   * - Removes authentication state and role value from session storage.
   * - Removes the stored cookie.
   * - Redirects the user to the login page.
   */
  const onLogout = () => {
    sessionStorage.removeItem('isAuth');
    sessionStorage.removeItem('role');
    removeCookie('token');
    history.push('/');
  };

  return (
    <header className="app__header header">
      <Link to={userRole === 'admin' ? '/dashboard' : '/all-projects'}>
        <img alt="Company Logo" src={CompanyLogo} width="140px" height="auto" />
      </Link>

      <nav className="header__nav">
        {userRole === 'admin' && pathname !== '/not-authorized' && (
          <Button type="link" size="large">
            <Link to="/dashboard">{t('dashboard')}</Link>
          </Button>
        )}

        {!isProduction && <LanguageSelector />}

        {pathname !== '/' && (
          <Button type="link" size="large" onClick={() => onLogout()}>
            {t('logout')}
          </Button>
        )}
      </nav>
    </header>
  );
};

export default Header;
