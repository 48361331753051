import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Select } from 'antd';
import jaFlag from '../assets/images/japan-flag.png';
import usaFlag from '../assets/images/usa-flag.png';

const { Option } = Select;

/**
 * Language selector component, Switches between English and Japanese.
 * Used only in development mode.
 *
 * @component
 * @version 1.0
 */
const LanguageSelector = () => {
  const isProduction = process.env.NODE_ENV === 'production';
  const defaultLanguage = isProduction ? 'ja' : 'en';
  const [language, setLanguage] = useState(defaultLanguage);
  const { i18n } = useTranslation();

  /**
   * Updates the web app language
   */
  useEffect(() => {
    i18n.changeLanguage(language);
  }, [language, i18n]);

  return (
    <Select
      style={{ color: 'white' }}
      bordered={false}
      defaultValue={defaultLanguage}
      onChange={(value) => setLanguage(value)}
    >
      <Option key="ja" value="ja">
        <b>日本語</b>
        <img style={{ margin: '0 0 5px 10px' }} alt="Japan Flag" src={jaFlag} />
      </Option>
    </Select>
  );
};

/*
<Option key="ja" value="ja">
  <b>日本語</b>
  <img style={{ margin: '0 0 5px 10px' }} alt="Japan Flag" src={jaFlag} />
</Option>
<Option key="en" value="en">
  <b>English</b>
  <img style={{ margin: '0 0 5px 10px' }} alt="USA Flag" src={usaFlag} />
</Option>
*/

export default LanguageSelector;
