import React from 'react';
import { useHistory } from 'react-router-dom';
import { useCookies } from 'react-cookie';
import { useTranslation } from 'react-i18next';
import CredentialsForm from '../components/CredentialsForm';
import Notification from '../components/Notification';
import login from '../api/login';

/**
 * Login route where the user can enter his username and password to use the web app
 *
 * @category Routes
 * @component
 * @version 1.0
 */
const Login = () => {
  const history = useHistory();
  const [, setCookie] = useCookies(['token']);
  const { t } = useTranslation();

  /**
   * Handles the logging in process
   * - Sends an API request to the backend for credentials validation
   * - On success save the user login state, role and token then redirects him
   *   to the appropriate route depends on his role
   * - On failure notifies the user to try again
   *
   * @param {object} values - Login credentials
   * @param {string} values.username - Username
   * @param {string} values.password - Password
   */
  const onLogin = async (values) => {
    const { status, data } = await login(values);

    if (status === 200) {
      const { roles, token } = data;
      const [role] = roles;

      sessionStorage.setItem('isAuth', true);
      sessionStorage.setItem('role', role);
      setCookie('token', token, { path: '/' });

      if (role === 'admin') {
        history.push('/dashboard');
      } else {
        history.push('/all-projects');
      }
    } else if (status === 401) {
      Notification(
        'error',
        t('invalidCredentials'),
        t('invalidCredentialsMessage')
      );
    } else {
      Notification('error', t('error'), t('serverDownMessage'));
    }
  };

  return (
    <main className="app__main login">
      <CredentialsForm onSubmit={onLogin} />
    </main>
  );
};

export default Login;
