import { useState, useRef, useEffect, useCallback } from 'react';

/**
 * A custom hook used to safely use useState hook api without worrying
 * about whether the component using it has been unmounted or not
 *
 * @component
 * @category Custom Hooks
 * @version 1.0
 * @param {any} initialValue - Initial state value
 * @returns {Array.<any|Function>} Current state value and State setter function
 */
const useSafeState = (initialValue) => {
  const [state, setState] = useState(initialValue);
  const isMounted = useRef();

  /**
   * Updates the current state value to the new one.
   * Only if the component referencing/using this state is still mounted
   *
   * @param {any} value - New state value
   */
  const updateState = (value) => isMounted.current && setState(value);

  /**
   * updateState function callback reference
   */
  const updateStateCB = useCallback(updateState, []);

  /**
   * On component mounts update isMounted reference value to true
   * On component unmounts update isMounted reference value to false
   */
  useEffect(() => {
    isMounted.current = true;

    return () => {
      isMounted.current = false;
    };
  }, []);

  return [state, updateStateCB];
};

export default useSafeState;
