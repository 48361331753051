import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Form, Input, Button, Select, Space, Collapse } from 'antd';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import Notification from '../components/Notification';

const { Option } = Select;

/**
 * Customized form component used in filling non-sensitive (credentials) defaultValues
 *
 * @component
 * @version 1.0
 */
const ProjectForm = ({ defaultValues, onSubmit }) => {
  const [hasExternalBudget, setHasExternalBudget] = useState(false);
  const { pathname } = useLocation();
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const formItemLayout = {
    labelCol: {
      span: 4
    },
    wrapperCol: {
      span: 20
    }
  };
  const buttonItemLayout = {
    wrapperCol: { span: 24 }
  };
  var countUpValue = 0;

  return (
    <Form
      {...formItemLayout}
      layout="horizontal"
      size="large"
      form={form}
      initialValues={
        defaultValues
          ? {
              categories: defaultValues.categories,
              aliases: defaultValues.aliases
            }
          : {
              categories: [
                { category: undefined, number: undefined, hours: undefined }
              ]
            }
      }
      onFinish={onSubmit}
    >
      <Form.Item
        label={t('projectTitle')}
        name="name"
        rules={[{ required: true, message: t('requiredField') }]}
        initialValue={defaultValues && defaultValues.name}
      >
        <Input type="text" />
      </Form.Item>

      <Form.Item
        label={t('orderID')}
        name="orderSheet"
        initialValue={defaultValues && defaultValues.orderSheet}
      >
        <Input type="text" />
      </Form.Item>

      <Form.Item
        label={t('clientOrderID')}
        name="customerOrderSheet"
        initialValue={defaultValues && defaultValues.customerOrderSheet}
      >
        <Input type="text" />
      </Form.Item>

      <Form.Item
        label={t('budget')}
        name="budget"
        rules={[{ required: true, message: t('requiredField') }]}
        initialValue={defaultValues && defaultValues.budget}
      >
        <Input type="number" min={0} prefix="¥" />
      </Form.Item>

      <Form.Item
        label={t('externalBudget')}
        name="externalBudget"
        rules={[{ required: true, message: t('requiredField') }]}
        initialValue={defaultValues && defaultValues.externalBudget}
      >
        <Input type="number" min={0} prefix="¥" />
      </Form.Item>

      {/* {(hasExternalBudget ||
        (pathname.includes('/edit-project/') &&
          defaultValues.type === 'external')) && (
        <Form.Item
          label={t('externalBudget')}
          name="externalBudget"
          rules={[{ required: true, message: t('requiredField') }]}
          initialValue={defaultValues && defaultValues.externalBudget}
        >
          <Input type="number" min={0} prefix="¥" />
        </Form.Item>
      )} */}

      <Form.Item
        label={t('status')}
        name="status"
        rules={[{ required: true, message: t('requiredField') }]}
        initialValue={defaultValues && defaultValues.statusKey}
      >
        <Select placeholder={t('pleaseSelect')}>
          <Option value={1}>{t('adjusting')}</Option>
          <Option value={2}>{t('inOperation')}</Option>
          <Option value={3}>{t('complete')}</Option>
          <Option value={4}>{t('end1')}</Option>
          <Option value={5}>{t('end2')}</Option>
        </Select>
      </Form.Item>

      {pathname === '/add-project' && (
        <Form.Item
          label={t('projectType')}
          name="type"
          rules={[{ required: true, message: t('requiredField') }]}
          initialValue={defaultValues && defaultValues.type}
        >
          <Select
            // placeholder={t('pleaseSelect')}
            // onChange={(value) => setHasExternalBudget(value === 2)}
          >
            <Option value={1}>{t('wakoProject')}</Option>
            <Option value={2}>{t('externalProject')}</Option>
          </Select>
        </Form.Item>
      )}

      <Form.Item
        label={
          <>
            <span style={{ fontSize: '20px', color: '#ff4d4f' }}>*</span> &nbsp;
            {t('categories')}
          </>
        }
      >
        <Form.List name="categories">
          {(fields, { add, remove }) => { 
            return (
              <>
                {fields.map((field) => (
                  <Space
                    key={field.key}
                    style={{ display: 'flex', marginBottom: 8 }}
                    align="start"
                  >
                    <Form.Item
                      {...field}
                      name={[field.name, 'category']}
                      fieldKey={[field.fieldKey, 'category']}
                      rules={[{ required: true, message: t('requiredField') }]}
                    >
                      <Input type="text" placeholder={t('categoryName')} />
                    </Form.Item>

                    <Form.Item
                      {...field}
                      name={[field.name, 'number']}
                      fieldKey={[field.fieldKey, 'number']}
                      rules={[{ required: true, message: t('requiredField') }]}
                    >
                      <Input type="number" min={0} placeholder={t('number')} />
                    </Form.Item>

                    <Form.Item
                      {...field}
                      name={[field.name, 'hours']}
                      fieldKey={[field.fieldKey, 'hours']}
                      rules={[{ required: true, message: t('requiredField') }]}
                    >
                      <Input type="number" min={0} placeholder={t('hours')} />
                    </Form.Item>

                    {pathname === '/add-project' && fields.length > 1 && (
                      <MinusCircleOutlined
                        style={{ marginTop: 12 }}
                        onClick={() => {
                          remove(field.name);
                        }}
                      />
                    )}

                    {pathname.includes('/edit-project/') && fields.length > 1 && (
                      <MinusCircleOutlined
                        style={{ marginTop: 12 }}
                        onClick={() => {
                          countUpValue = 0
                          remove(field.name);
                        }}
                      />
                    )}

                  </Space>
                ))}
                {pathname === '/add-project' && (
                  <Form.Item>
                    <Button
                      type="dashed"
                      onClick={() => {
                        add();
                      }}
                      block
                    >
                      <PlusOutlined /> {t('addField')}
                    </Button>
                  </Form.Item>
                )}
                
                {pathname.includes('/edit-project/') && (
                  <Form.Item>
                    <Button
                      type="dashed"
                      onClick={() => {
                        countUpValue++;
                        console.info(countUpValue)
                        if(countUpValue < 2){
                          add();
                        }else {
                          Notification('error', t('error'), t('notInsert'));
                        }
                      }}
                      block
                    >
                      <PlusOutlined /> {t('addField')}
                    </Button>
                  </Form.Item>
                )}
              </>
            );
          }}
        </Form.List>
      </Form.Item>

      {pathname.includes('/edit-project/') && (
        <Form.Item
          label={t('layersNames')}
          rules={[{ required: true, message: t('requiredField') }]}
        >
          <Collapse>
            <Collapse.Panel header={t('clickToExpand')} key="1">
              <Form.List name="aliases">
                {(fields) => (
                  <>
                    {fields.map((field, index) => (
                      <Space
                        key={field.key}
                        style={{ display: 'flex', marginBottom: 8 }}
                        align="start"
                      >
                        <Form.Item
                          {...field}
                          label={defaultValues.aliases[index].layerDepth}
                          name={[field.name, 'layerAlias']}
                          fieldKey={[field.fieldKey, 'layerAlias']}
                        >
                          <Input type="text" placeholder={t('layerName')} />
                        </Form.Item>
                      </Space>
                    ))}
                  </>
                )}
              </Form.List>
            </Collapse.Panel>
          </Collapse>
        </Form.Item>
      )}

      <Form.Item {...buttonItemLayout}>
        <Button
          type="primary"
          htmlType="submit"
          style={{ display: 'block', margin: 'auto', width: 300 }}
        >
          {t('submit')}
        </Button>
      </Form.Item>
    </Form>
  );
};

ProjectForm.propTypes = {
  /** Form default values */
  defaultValues: PropTypes.shape({
    /** Project row key  */
    key: PropTypes.string,
    /** Project id in the database  */
    id: PropTypes.number,
    /** Project name */
    name: PropTypes.string,
    /** Order Id */
    orderSheet: PropTypes.string,
    /** Client order Id */
    customerOrderSheet: PropTypes.string,
    /** Project budget */
    budget: PropTypes.number,
    /** Project external budget */
    externalBudget: PropTypes.number,
    /** Project status label */
    status: PropTypes.string,
    /** Project status code */
    statusKey: PropTypes.number,
    /** Project type code */
    type: PropTypes.number,
    /** Project work force categories */
    categories: PropTypes.arrayOf(
      PropTypes.shape({
        /** Category id in the database */
        categoryId: PropTypes.number,
        /** Category name */
        category: PropTypes.string,
        /**
         * Number of workers per category
         * @ignore
         */
        number: PropTypes.number,
        /** Number of hours worked per category */
        hours: PropTypes.number
      })
    ),
    /** Layers' names */
    aliases: PropTypes.arrayOf(
      PropTypes.shape({
        projectId: PropTypes.number,
        id: PropTypes.number,
        layerDepth: PropTypes.number,
        layerAlias: PropTypes.string
      })
    )
  }),
  /** Form submit handler */
  onSubmit: PropTypes.func.isRequired
};

ProjectForm.defaultProps = {
  defaultValues: null
};

export default ProjectForm;
