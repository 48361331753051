import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Form } from 'antd';
import useSafeState from './useSafeState';
import Notification from '../components/Notification';
import { updateProject, deleteProject } from '../api/projects';

/**
 * A custom hook which exposes data and functions to be reused in the table component in different pages.
 *
 * @component
 * @category Custom Hooks
 * @version 1.0
 * @param {object} rows - Table rows data
 *  - Row's object shape varies based on usage the only required property is a unique key.
 *  - Row's object properties' names should match the columns' dataIndex field
 * @returns {{form: object, data: object[], setData: Function,
 * setEditingKey: Function, isEditing: Function, edit: Function,
 * save: Function, remove: Function, cancel: Function}} Rows data state and Helper functions
 */
const useTable = (rows) => {
  const [data, setData] = useSafeState(rows);
  const [editingKey, setEditingKey] = useState('');
  const {
    t,
    i18n: { language }
  } = useTranslation();
  const [form] = Form.useForm();

  /**
   * Cancels the current active row in edit mode
   */
  const cancel = () => setEditingKey('');

  /**
   * Gets the status cell key and label values in the current selected language
   *
   * @param {number|string} value - User selected value
   * @returns {{statusKey: number, statusLabel: string}} Status cell data
   */
  const getStatusColumnCell = (value) => {
    const statusOptions = [
      { key: 1, label: { en: 'Adjusting', ja: '調整中' } },
      { key: 2, label: { en: 'In Operation', ja: '稼働中' } },
      { key: 3, label: { en: 'Complete', ja: '完了' } },
      { key: 4, label: { en: 'End1', ja: '終了' } },
      { key: 5, label: { en: 'End2', ja: '打ち切り' } }
    ];

    const { key, label } = statusOptions.find(
      (option) => option.key === value || option.label[language] === value
    );

    return { statusKey: key, statusLabel: label[language] };
  };

  /**
   * Shows notification to the user
   *
   * @param {number} statusCode - API request status code
   */
  const notify = (statusCode) => {
    if (statusCode === 200) {
      Notification('success', t('great'), t('updatesSaved'));
    } else {
      Notification('error', t('error'), t('tryAgain'));
    }
  };

  /**
   * Checks whether the selected row is in edit mode or not
   *
   * @param {object} row - Table row data
   * @param {string} row.key - Table row key
   * @returns {boolean}
   */
  const isEditing = ({ key }) => key === editingKey;

  /**
   * Opens the selected row in edit mode
   *
   * @param {object} row - Table editable row's cells
   * The editable row's shape varies depends on the table
   */
  const edit = (row) => {
    form.setFieldsValue({
      ...row,
      ...(row.status && {
        status: getStatusColumnCell(row.statusKey).statusLabel
      })
    });
    setEditingKey(row.key);
  };

  /**
   * Updates the table rows data with the new user modifications
   *
   * @param {object} row - Table row data
   * @param {string} row.key - Table row key
   */
  const save = async ({ key }) => {
    try {
      const editedRow = await form.validateFields();
      const originalRowsCopy = [...data];
      const index = originalRowsCopy.findIndex((item) => key === item.key);
      const hasStatusColumn = editedRow.status;
      const hasCategoryColumn = editedRow.category;

      if (index > -1) {
        const originalRow = originalRowsCopy[index];

        if (hasStatusColumn) {
          const { statusKey, statusLabel } = getStatusColumnCell(
            editedRow.status
          );

          originalRowsCopy.splice(index, 1, {
            ...originalRow,
            ...editedRow,
            statusKey,
            status: statusLabel
          });

          const repsonse = await updateProject(originalRowsCopy[index].id, {
            name: originalRowsCopy[index].name,
            orderSheet: originalRowsCopy[index].orderSheet,
            customerOrderSheet: originalRowsCopy[index].customerOrderSheet,
            budget: originalRowsCopy[index].budget,
            status: statusKey
          });

          if (
            statusKey === 5 ||
            (originalRow.statusKey === 5 && statusKey !== 5)
          ) {
            originalRowsCopy.splice(index, 1);
          }

          notify(repsonse.status);
        } else if (hasCategoryColumn) {
          originalRowsCopy.splice(index, 1, {
            ...originalRow,
            ...editedRow,
            category: editedRow.category
          });
        } else {
          originalRowsCopy.splice(index, 1, { ...originalRow, ...editedRow });
        }
      } else {
        originalRowsCopy.push(editedRow);
      }

      setData(originalRowsCopy);
      cancel();
    } catch (errInfo) {}
  };

  /**
   * Removes the selected row from the table rows data.
   * - It sends API request to the backend with the required data.
   * - It notifies the user whether the delete request was successful or failure.
   *
   * @param {string} key
   */
  const remove = async (key) => {
    const { id } = data.find((item) => item.key === key);
    const { status } = await deleteProject(id);

    notify(status);
    setData((prevState) => prevState.filter((item) => item.key !== key));
    cancel();
  };

  return {
    form,
    data,
    setData,
    isEditing,
    edit,
    save,
    remove,
    cancel
  };
};

export default useTable;
