import React from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Typography } from 'antd';
import { CompassOutlined, TagOutlined } from '@ant-design/icons';
import Notification from '../components/Notification';
import BackButton from '../components/BackButton';
import ProjectForm from '../components/ProjectForm';
import { updateProject } from '../api/projects';

/**
 * Project advanced editing route (Accessable only by the admin)
 *
 * @category Routes
 * @component
 * @version 1.0
 */

const EditProject = () => {
  const {
    state: { number: projectNumber, id: projectID, record }
  } = useLocation();
  const history = useHistory();
  const { t } = useTranslation();

  /**
   * Handles the form submition logic
   * - Sends an API request with the collected user inputs to the backend for project creation
   * - Redirects the user to all-projects route
   * - Notifies the user whether the creation request was successful or failure
   *
   * @param {Object} values User inputs
   * @param {string} values.name Project name
   * @param {string} values.orderSheet Order Id
   * @param {string} values.customerOrderSheet Client order Id
   * @param {string} values.budget Project budget
   * @param {string} values.externalBudget Project external budget
   * @param {number} values.status Project status code
   * @param {number} values.type Project type code
   * @param {Array.<{category: string, number: string, hours: string}>} values.categories Project work force categories
   */
  const onSubmit = async (values) => {
    const { categories: recordCategories } = record;
    const { categories: formCategories } = values;
    const categories = [];
    const checkIndex = formCategories.length - recordCategories.length;

    console.info('recordCategories');
    console.info(recordCategories);
    console.info('formCategories');
    console.info(formCategories);
    console.info('categories');
    console.info(categories);
    console.info('projectID');
    console.info(projectID);
    console.info('checkIndex');
    console.info(checkIndex);

    formCategories.forEach((category, index) => {
      console.info('category');
      console.info(category);
      console.info('index');
      console.info(index);

      if(index + checkIndex > recordCategories.length){
        console.info('ここから新規追加');
        var categoryId  = 0;
      }else{
        var { categoryId }  = recordCategories[index];
      }
      categories.push({ ...category, categoryId });
      console.info('categoryId');
      console.info(categoryId);
      console.info('categories');
      console.info(categories);
    });

    const { status } = await updateProject(projectID, {
      ...values,
      categories
    });
    console.info('updateProject-end');

    if (status === 200) {
      Notification('success', t('great'), t('updatesSaved'));
      history.push('/all-projects');
    } else {
      Notification('error', t('error'), t('serverDownMessage'));
    }
  };

  return (
    <main className="app__main edit-project">
      <div className="edit-project__controls">
        <BackButton path="/all-projects" />

        <Typography.Title style={{ marginTop: 10 }} level={4}>
          <TagOutlined style={{ color: '#faad14' }} /> {projectNumber}
        </Typography.Title>
      </div>

      <ProjectForm defaultValues={record} onSubmit={onSubmit} />
    </main>
  );
};

export default EditProject;
