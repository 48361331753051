import React, { useState, useEffect, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Tag, Button, Switch } from 'antd';
import {
  ScissorOutlined,
  SyncOutlined,
  CheckCircleOutlined,
  StopOutlined
} from '@ant-design/icons';
import Notification from '../components/Notification';
import BackButton from '../components/BackButton';
import Table from '../components/Table';
import useSafeState from '../hooks/useSafeState';
import useTable from '../hooks/useTable';
import { getAllProjects } from '../api/projects';

/**
 * Projects table page where the user can find all the created projects,
 * apply some operations on them like editing, archiving, etc.. and
 * navigate to all the related pages (work force, project tree and reports)
 *
 * @category Routes
 * @component
 * @version 1.0
 */
const ProjectsTable = () => {
  const userRole = sessionStorage.getItem('role');
  const [isFetching, setIsFetching] = useSafeState(true);
  const [isArchived, setIsArchived] = useState(false);
  const [budgetSwitchMode, setBudgetSwitchMode] = useState(true);
  const history = useHistory();
  const { t } = useTranslation();
  const {
    form,
    data,
    setData,
    isEditing,
    edit,
    save,
    remove,
    cancel
  } = useTable([]);
  const statusOptions = [
    { key: 1, label: 'Adjusting' },
    { key: 2, label: 'In Operation' },
    { key: 3, label: 'Complete' },
    { key: 4, label: 'End1' },
    { key: 5, label: 'End2' }
  ];

  /**
   * Adds a quantity symbol to budget number if applicable
   *
   * @param {number} budget - Bugdet number
   * @returns {string} string containing new budget with symbol
   */
  const quantifyBudget = (budget) => {
    if (budget >= 1000000000000) {
      let str = Math.floor(budget / 1000000000000).toString();
      if (str.length < 4) {
        str = (budget / 1000000000000).toPrecision(4).toString();
      }
      return `${str}兆円`;
    }
    if (budget >= 100000000) {
      let str = Math.floor(budget / 100000000).toString();
      if (str.length < 4) {
        str = (budget / 100000000).toPrecision(4).toString();
      }
      return `${str}億円`;
    }
    if (budget >= 10000) {
      let str = Math.floor(budget / 10000).toString();
      if (str.length < 4) {
        str = (budget / 10000).toPrecision(4).toString();
      }
      return `${str}万円`;
    }
    return `${budget.toString()}円`;
  };

  /**
   * Converts an integer number to a string with commas between each 3 digits.
   *
   * @param {number} number - Number value
   * @returns {string} Formatted number
   */

   function addCommas(nStr) {
    nStr += '';
    var x = nStr.split('.');
    var x1 = x[0];
    var x2 = x.length > 1 ? '.' + x[1] : '';
    var rgx = /(\d+)(\d{3})/;
    while (rgx.test(x1)) {
        x1 = x1.replace(rgx, '$1' + ',' + '$2');
    }
    return x1 + x2;
}

  const formatBudget = (number) =>
    `${addCommas(number.toString())}円`;

  /**
   * Creates a colorful animated tag component based on the project status code
   *
   * @param {number} status - Project status code
   * @returns {jsx} Tag component
   */
  const createStatusTag = (status) => {
    let text;
    let color;
    let icon;

    if (status === 1) {
      text = 'adjusting';
      color = 'blue';
      icon = <ScissorOutlined />;
    } else if (status === 2) {
      text = 'inOperation';
      color = 'orange';
      icon = <SyncOutlined spin />;
    } else if (status === 3) {
      text = 'complete';
      color = 'green';
      icon = <CheckCircleOutlined />;
    } else if (status === 4) {
      text = 'end1';
      color = 'red';
      icon = <StopOutlined />;
    } else if (status === 5) {
      text = 'end2';
      color = 'red';
      icon = <StopOutlined />;
    }

    return (
      status && (
        <Tag color={color} icon={icon}>
          {t(text)}
        </Tag>
      )
    );
  };

  /**
   * Creates the appropriate component code to be shown inside the corresponding cell
   * based on the table column index
   *
   * @param {string} dataIndex - Table column index
   * @returns {string} Component code
   */
  const getCellType = (dataIndex) => {
    if (dataIndex === 'budget' || dataIndex === 'workForce') {
      return 'number';
    }
    if (dataIndex === 'status') {
      return 'select';
    }
    if (dataIndex === 'operations') {
      return 'button-group';
    }
    return 'text';
  };

  /**
   * Creates table columns based on the logged in user's role
   *
   * @returns {Array.<{title: string, dataIndex: string,
   * editable: boolean, width: string, render: Function, onCell: Function}>} Table columns
   */
  const getTableColumns = () => {
    let columns = [];

    if (userRole === 'admin') {
      columns = [
        {
          title: t('projectID'),
          dataIndex: 'number',
          editable: false,
          fixed: 'left',
          width: '10%'
        },
        {
          title: t('projectTitle'),
          dataIndex: 'name',
          editable: true,
          width: userRole === 'admin' && '20%'
        },
        {
          title: t('orderID'),
          dataIndex: 'orderSheet',
          editable: true,
          width: '10%'
        },
        {
          title: t('clientOrderID'),
          dataIndex: 'customerOrderSheet',
          editable: true,
          width: '10%'
        },
        {
          title: (
            <>
              {t('budget')}{' '}
              <Switch
                loading={isFetching}
                disabled={isFetching}
                checked={budgetSwitchMode}
                unCheckedChildren={t('exactBudget')}
                checkedChildren={t('truncBudget')}
                onChange={setBudgetSwitchMode}
                style={{ marginLeft: '20px' }}
              />
            </>
          ),
          dataIndex: 'budget',
          editable: true,
          width: '15%',
          render: (_, record) =>
            budgetSwitchMode
              ? quantifyBudget(record.budget)
              : formatBudget(record.budget)
        },
        {
          title: t('status'),
          dataIndex: 'status',
          editable: true,
          render: (_, record) => createStatusTag(record.statusKey),
          width: '10%'
        }
      ];
    } else {
      columns = [
        {
          title: t('projectTitle'),
          dataIndex: 'name'
        }
      ];
    }

    const navigate = (to, state) => {
      history.push({
        pathname: history.location.pathname + to,
        state
      });
    };

    const operationsButtons = [
      {
        title: t('quickEdit'),
        isPrivate: userRole !== 'admin',
        onClick: (record) => edit(record)
      },
      {
        title: t('workForce'),
        isPrivate: false,
        onClick: (record, number) =>
          navigate(`/work-force/${record.number}`, { number, id: record.id })
      },
      {
        title: t('projectTree'),
        isPrivate: false,
        onClick: (record, number) =>
          navigate(`/project-tree/${record.number}`, { number, id: record.id })
      },
      {
        title: t('reports'),
        isPrivate: userRole !== 'admin',
        onClick: (record, number) =>
          navigate(`/reports/${record.number}`, { number, id: record.id })
      }
    ];

    const operations = {
      title: t('operations'),
      dataIndex: 'operations',
      editable: true,
      width: userRole === 'admin' && '20%',
      render: (_, record) => {
        const { number: projectNumber } = data.find(
          ({ key }) => key === record.key
        );

        return operationsButtons.map(
          ({ title, isPrivate, onClick }) =>
            !isPrivate && (
              <Button
                key={title}
                style={
                  userRole === 'admin' ? { width: '50%' } : { width: '100%' }
                }
                onClick={() => onClick(record, projectNumber)}
              >
                {title}
              </Button>
            )
        );
      }
    };

    columns.push(operations);

    const mergedColumns = columns.map((col) => {
      if (!col.editable) {
        return col;
      }

      return {
        ...col,
        onCell: (record) => ({
          record,
          selectOptions: statusOptions,
          inputType: getCellType(col.dataIndex),
          dataIndex: col.dataIndex,
          title: col.title,
          editing: isEditing(record),
          onSave: () => save({ key: record.key }),
          remove,
          cancel,
          isArchived
        })
      };
    });

    return mergedColumns;
  };

  /**
   * Updates the projects table data
   * - Sends an API request to the backend to save the changes
   * - Updates the table UI
   */
  const updateTableData = async () => {
    setIsFetching(true);
    if (!isArchived) {
      setIsArchived(false);
    }

    const response = await getAllProjects(isArchived);
    const { status } = response;

    if (status === 200) {
      const { data: tableData } = response;

      setData(tableData);
      setIsFetching(false);
    } else {
      Notification('error', t('error'), t('serverDownMessage'));
    }
  };

  /**
   * updateTableData function callback reference
   */
  const updateTableCB = useCallback(updateTableData, [
    isArchived,
    setIsFetching,
    setData,
    t
  ]);

  /**
   * Updates the switch mode and table UI
   *
   * @param {boolean} isChecked - Whether the switch is toggled or not
   */
  const onToggle = (isChecked) => {
    cancel();
    setIsArchived(isChecked);
  };

  /**
   * Updates the table rows data whenever the updateTableCB reference changes
   */
  useEffect(() => {
    updateTableCB();
  }, [updateTableCB]);

  return (
    <main className="app__main projects-table">
      {userRole === 'admin' && (
        <div className="projects-table__controls">
          <BackButton path="/dashboard" />

          <Switch
            loading={isFetching}
            disabled={isFetching}
            checked={isArchived}
            unCheckedChildren={t('currentProjects')}
            checkedChildren={t('archivedProjects')}
            onChange={onToggle}
          />
        </div>
      )}

      <Table
        form={form}
        rows={data}
        columns={getTableColumns()}
        loading={isFetching}
        isEditable
      />
    </main>
  );
};

export default ProjectsTable;
