import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useLocation, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Input, InputNumber, Form, Select, Button, Modal } from 'antd';
import {
  CheckOutlined,
  CloseOutlined,
  UploadOutlined,
  SettingOutlined,
  DeleteOutlined
} from '@ant-design/icons';
import Uploader from './Uploader';

/**
 * Editable table cell, To use it:
 * - Define a component code (e.g. list) in the getInputNode function.
 * - Import the required component.
 *
 * @component
 * @version 1.0
 */
const EditableCell = (props) => {
  const {
    editing,
    dataIndex,
    inputType,
    record,
    selectOptions,
    children,
    onSave,
    remove,
    cancel,
    isArchived,
    ...restProps
  } = props;
  const [modalMode, setModalMode] = useState(false);
  const location = useLocation();
  const history = useHistory();
  const { t } = useTranslation();
  const userRole = sessionStorage.getItem('role');

  /**
   * Translates the option label from English to Japanese.
   *
   * @param {string} option - Option label
   * @returns {string} Translated option label
   */
  const getOptionLabel = (option) => {
    let text;

    if (option === 'Adjusting') {
      text = t('adjusting');
    } else if (option === 'In Operation') {
      text = t('inOperation');
    } else if (option === 'Complete') {
      text = t('complete');
    } else if (option === 'End1') {
      text = t('end1');
    } else if (option === 'End2') {
      text = t('end2');
    } else {
      text = option;
    }

    return text;
  };

  /**
   * Creates input type component based on the component code.
   *
   * @param {string} type - Component code
   * @returns {jsx} Input component
   */
  const getInputNode = (type) => {
    if (type === 'number') {
      return <InputNumber type="number" min={0} />;
    }
    if (type === 'select') {
      return (
        <Select style={{ width: 120 }}>
          {selectOptions.map((option) => (
            <Select.Option
              key={option.key}
              /* value={`${option.key}-${option.label}`} */
              value={option.key}
            >
              {getOptionLabel(option.label)}
            </Select.Option>
          ))}
        </Select>
      );
    }
    if (type === 'button-group') {
      const { name: modalTitle, id: projectID, type: projectType } = record;

      return (
        <div style={{ display: 'flex', justifyContent: 'center', gap: '1rem' }}>
          {location.pathname === '/all-projects' && userRole === 'admin' && (
            <>
              <Button
                type="link"
                icon={<UploadOutlined className="icons" />}
                onClick={() => setModalMode(true)}
              />

              <Modal
                title={modalTitle}
                visible={modalMode}
                footer={null}
                onCancel={() => setModalMode(false)}
              >
                <Uploader
                  className="icons"
                  projectID={projectID}
                  projectType={projectType}
                />
              </Modal>

              <Button
                type="link"
                icon={<SettingOutlined className="icons settings-icon" />}
                onClick={() =>
                  history.push({
                    pathname: `/all-projects/edit-project/${record.number}`,
                    state: { number: record.number, id: record.id, record }
                  })
                }
              />
            </>
          )}

          <Button
            type="link"
            icon={<CheckOutlined className="icons true-icon" />}
            onClick={() => onSave()}
          />

          <Button
            type="link"
            icon={<CloseOutlined className="icons false-icon" />}
            onClick={() => cancel()}
          />

          {userRole === 'admin' && isArchived && (
            <Button
              type="link"
              icon={<DeleteOutlined className="icons delete-icon" />}
              onClick={() => remove(record.key)}
            />
          )}
        </div>
      );
    }

    return <Input />;
  };

  return (
    <td {...restProps}>
      {editing ? (
        <Form.Item
          name={dataIndex}
          style={{
            margin: 0
          }}
          rules={
            location.pathname.includes('work-force') && [
              {
                required: inputType !== 'button-group',
                message: t('requiredField')
              }
            ]
          }
        >
          {getInputNode(inputType)}
        </Form.Item>
      ) : (
        children
      )}
    </td>
  );
};

EditableCell.propTypes = {
  /** Whether the edit button is pressed or not */
  editing: PropTypes.bool,
  /** Column index value (Applicable only in ProjectsTable, Workforce and ProjectTree routes) */
  dataIndex: PropTypes.string,
  /** Input component code (Applicable only in ProjectsTable, Workforce and ProjectTree routes) */
  inputType: PropTypes.string,
  /** Row data (Applicable only in ProjectsTable, Workforce and ProjectTree routes) */
  record: PropTypes.object,
  /** Row data (Applicable only in ProjectsTable and Workforce routes) */
  selectOptions: PropTypes.arrayOf(
    PropTypes.shape({
      /** Option id in the database */
      key: PropTypes.number,
      /** Option label to display */
      label: PropTypes.string
    })
  ),
  /** Whether the displayed projects are archived or not (Applicable only in ProjectsTable) */
  isArchived: PropTypes.bool,
  /** Default table rows' cells */
  children: PropTypes.array,
  /** Save modified row */
  onSave: PropTypes.func,
  /** Delete row */
  remove: PropTypes.func,
  /** Cancel any opened edit operation */
  cancel: PropTypes.func
};

EditableCell.defaultProps = {
  editing: false,
  dataIndex: null,
  inputType: null,
  record: null,
  selectOptions: null,
  isArchived: false,
  children: null,
  onSave: () => {},
  remove: () => {},
  cancel: () => {}
};

export default EditableCell;
