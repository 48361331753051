import React from 'react';
import { useTranslation } from 'react-i18next';
import { Typography } from 'antd';
import NotFound404 from '../assets/images/not-found.svg';

const viewportWidth = window.innerWidth;

const NotFound = () => {
  const { t } = useTranslation();
  const { Title } = Typography;

  return (
    <main className="app__main not-found">
      <Title level={viewportWidth > 600 ? 3 : 4}>{t('404Message')}</Title>
      <img alt="404" src={NotFound404} />
    </main>
  );
};

export default NotFound;
