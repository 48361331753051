import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Card } from 'antd';
import AddProjectSVG from '../assets/images/add-project.svg';
import ViewAllProjectsSVG from '../assets/images/view-projects.svg';
import UpdateCredentialsSVG from '../assets/images/update-credentials.svg';

/**
 * Admin dashboard panel route (Accessable only by the admin)
 *
 * @category Routes
 * @component
 * @version 1.0
 */

const Dashboard = () => {
  const { t } = useTranslation();

  /**
   * Creates a card-like component which is described by an image and a title
   *
   * @param {string} title - Card title
   * @param {string} image - Card image static path
   * @returns {jsx} Card component
   */
  const createCard = (title, image) => (
    <Card
      className="cards-container__card"
      cover={<img className="card__cover" alt={title} src={image} />}
      hoverable
    >
      <Card.Meta title={title} />
    </Card>
  );

  return (
    <main className="app__main dashboard">
      <div className="dashboard__cards-container">
        <Link to="/add-project">
          {createCard(t('addProject'), AddProjectSVG)}
        </Link>

        <Link to="/all-projects">
          {createCard(t('viewAllProjects'), ViewAllProjectsSVG)}
        </Link>

        <Link to="/update-credentials">
          {createCard(t('updateCredentials'), UpdateCredentialsSVG)}
        </Link>
      </div>
    </main>
  );
};

export default Dashboard;
