import React from 'react';
import { useTranslation } from 'react-i18next';

/**
 * Web app layout: Footer component
 *
 * @component
 * @version 1.0
 */
const Footer = () => {
  const { t } = useTranslation();

  return <footer className="app__footer footer">{t('footerText')}</footer>;
};

export default Footer;
